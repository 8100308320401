import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../../store/slices/messages";
import { CustomInput } from "../../common/baseForm";
import { useForm } from "react-hook-form";
import { register as Registration } from "../../../store/slices/auth";
import { useEffect, useRef, useState } from "react";
import { TextModal } from "../../Modal/TextModal";
import { SignupArr } from "../../../utils";
import axios from "axios";
import { CustomSelect } from "../../Forms/Select/CustomSelect";
import { toast } from "react-toastify";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

const Signup = () => {
  const [succesful, setSuccesful] = useState(false);
  const [notsucccessfull, setNotsucccessfull] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const [countrySelected, setCountrySelected] = useState("Nigeria");

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionState, setSelectedOptionState] = useState(null);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [phone, setPhone] = useState("");

  const contriesUrl = `${process.env.REACT_APP_BASE_URL}/countries`;

  const fetchCountries = () => {
    axios
      .get(contriesUrl)
      .then((res) => {
        if (res.data) {
          setCountryList(res.data.data);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  // console.log("CYL", countryList);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchStates = (statesUrl) => {
    if (statesUrl) {
      axios
        .get(statesUrl)
        .then((res) => {
          console.log("RES", res.data.data);
          setStateList(res.data.data);
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  };

  const handleChangeCountry = (selectedOption) => {
    setStateList(null);
    setSelectedOptionState(null);
    setSelectedOption(selectedOption);
    console.log("Option selected:", selectedOption.value);
    if (selectedOption.value) {
      fetchStates(
        `${process.env.REACT_APP_BASE_URL}/states/${selectedOption.value}`
      );
    }
  };

  const fetchCity = (cityUrl) => {
    if (cityUrl) {
      axios
        .get(cityUrl)
        .then((res) => {
          console.log("RES", res.data.data);
          setCityList(res.data.data);
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  };

  const handleChangeState = (selectedOption) => {
    setCityList(null);
    setSelectedOptionCity(null);
    setSelectedOptionState(selectedOption);
    console.log("Option selected State:", selectedOption.value);
    if (selectedOption.value) {
      fetchCity(
        `${process.env.REACT_APP_BASE_URL}/cities/${selectedOption.value}`
      );
    }
  };

  const handleChangeCity = (selectedOption) => {
    setSelectedOptionCity(selectedOption);
    console.log("Option selected City:", selectedOption.value);
  };

  console.log("Sataat", selectedOptionState);

  const onSubmit = (data) => {
    console.log(data);

    const UserData = {
      country: selectedOption.value,
      state: selectedOptionState.value,
      city: selectedOptionCity.value,
      username: data.Username,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      pin: data.transaction_pin,
      phone: data.phone,
      business_name: data.business_name,
      address: data.address,
      account_type: "1",
      referral: data.referral,
    };

    console.log("USEHEHHEH", UserData);

    const url = `${process.env.REACT_APP_BASE_URL}/auth/signup`;

    dispatch(Registration(UserData))
      .then((res) => {
        console.log("RES", res);
        if (res?.meta.requestStatus === "fulfilled") {
          setNotsucccessfull(false);
          setSuccesful(true);
          setIsOpen(true);
          console.log("Res was okayyyy");
          setTimeout(() => {
            window.location.href =
              window.location.protocol + "//" + window.location.host + "/login";
          }, 1000);
        } else {
          setSuccesful(false);
          setIsOpen(true);
          setNotsucccessfull(true);
        }
      })
      .catch((e) => {
        setSuccesful(false);
        setIsOpen(true);
        setNotsucccessfull(true);
      });
  };

  const ToastSucess = () => {
    toast.success("Successful");
    setIsOpen(false);
  };

  const ToastFail = () => {
    toast.error("Failed");
    setIsOpen(false);
  };

  return (
    <div className="min-h-screen flex">
      <div className=" min-h-screen bg-gradient-to-tr from-[#009897] to-[#fff] lto-[#EF6C25] lbg-green-500 max-w-[40vw] w-full hidden tablet:flex justify-center items-center">
        <img src="/assets/img/favicon.png" className=" max-w-[200px]" alt="" />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" flex flex-col  tablet:max-w-[60vw] w-full tablet:mx-auto justify-between items-center bg-white py-5 px-6"
      >
        <h1 className=" text-3xl font-medium">Sign up</h1>
        <div className=" mt-6 flex justify-center items-center w-[70%]">
          <div className="w-full grid grid-cols-1 tablet:grid-cols-2 gap-x-8 gap-y-4 mb-6">
            {SignupArr.map((item, index) => (
              <CustomInput
                key={index}
                label={item.label}
                Errorlabel={
                  errors[item.name] && (
                    <p className=" text-red-400">{item.label} is required</p>
                  )
                }
                {...register(item.name, { required: true })}
              />
            ))}
            <CustomSelect
              label={"Country"}
              options={countryList}
              value={selectedOption}
              onChange={handleChangeCountry}
            />
            {stateList && (
              <CustomSelect
                label={"State"}
                options={stateList}
                value={selectedOptionState}
                onChange={handleChangeState}
              />
            )}
            {cityList && (
              <CustomSelect
                label={"City"}
                options={cityList}
                value={selectedOptionCity}
                onChange={handleChangeCity}
              />
            )}
            <div className=" text-base font-normal">
              <label className=" font-medium text-base">
                {errors["phone"] ? (
                  <span className=" text-red-400">
                    Phone number is required
                  </span>
                ) : (
                  "Phone number"
                )}
              </label>
              <input
                type="tel"
                // value={phone}
                // onChange={(e) => setPhone(e.target.value)}
                {...register("phone", { required: "Phone number is required" })}
                className="max-w-[300px] pl-[10px] border-[1.5px] border-solid border-[#D0D5DD] rounded-lg h-[50px] w-full"
                placeholder="+2348723872373"
              />
            </div>
            <CustomInput
              label={"Referral ID (Optional)"}
              Errorlabel={
                errors["referral"] && (
                  <p className=" text-red-400">{errors.referral}</p>
                )
              }
              {...register("referral")}
            />
            <CustomInput
              label={"Transaction pin"}
              maxLength={4}
              Errorlabel={
                errors["transaction_pin"] && (
                  <p className=" text-red-400">
                    {"Transaction pin"} is required
                  </p>
                )
              }
              {...register("transaction_pin", { required: true, maxLength: 4 })}
            />

            <div className=" text-base font-normal">
              <label className=" font-medium text-base">
                {errors.password ? (
                  <span className=" text-red-400">
                    {errors.password.message}
                  </span>
                ) : (
                  "Password"
                )}
              </label>
              <input
                className="max-w-[300px] pl-[10px] border-[1.5px] border-solid border-[#D0D5DD] rounded-lg h-[50px] w-full"
                {...register("password", { required: "Password is required" })}
                type="password"
                placeholder="Password"
              />
            </div>

            <div className=" text-base font-normal">
              <label className=" font-medium text-base">
                {errors.confirmPassword ? (
                  <span className=" text-red-400">
                    {errors.confirmPassword.message}
                  </span>
                ) : (
                  "Confirm password"
                )}
              </label>
              <input
                className="max-w-[300px] pl-[10px] border-[1.5px] border-solid border-[#D0D5DD] rounded-lg h-[50px] w-full"
                {...register("confirmPassword", {
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
                type="password"
                placeholder="Confirm password"
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className=" activew max-w-[300px] py-3 text-base w-full"
        >
          Submit
        </button>
        <p className="text-base text-center mt-2">
          Already have an account? <span onClick={()=>{setTimeout(() => {
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/login";
          }, 300);}} className=" text-blue-400 cursor-pointer">Login</span>
        </p>
      </form>
      {succesful && isOpen && ToastSucess()}
      {notsucccessfull && isOpen && ToastFail()}
    </div>
  );
};

export default Signup;
