import React, { useState, useEffect, useRef } from 'react';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import Spinner from '../Spinner';
import { Modal } from 'react-modal';
import { ProfileAvatar } from '../../../assets/Profilepic';
import { Avatar } from '@material-tailwind/react';
import PinModal from '../../../pincheck';
import { decryptData } from '../../../../utils/util';
import { toast } from 'react-toastify';
import ScuccessModal from '../../../success';
import { Link, useNavigate } from "react-router-dom";



export const TransferPage = () => {
    const navigate = useNavigate()
    let user = null;
    const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
    if (mkLocalData) {
        const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
        const originalData = decryptData(mkLocalData, salt);
        user = JSON.parse(originalData);
    }
    // let user = JSON.parse(localStorage.getItem("user"));
    const baseURL = process.env.REACT_APP_MEDIA_URL;
    const picture = 'upload/profilepic/';
    // setInterval(() => {
    //     business = JSON.parse(localStorage.getItem("business"));
    // }, 1000);
    
    // function NetworkItems() {

    // }
    const [selectedItems, setSelectedItems] = useState([]);
    

    const [selectedItem, setSelectedItem] = useState(null);
    const [vbutton, setvButton] = useState(false);
    const [response, setResponse] = useState(null);
    const [receiverid, setReceiverID] = useState(null);
    const [phone_number, setPhonenumber] = useState(null);
    const [accountno, setAccountNo] = useState(null);
    const stopLoading = () => setLoading(false);
    const [loading, setLoading] = useState(false);
    const [ismoPaluser, setIsmoPaluser] = useState(false);
    const [isBankUser, setIsBankuser] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenSuccess, setIsModalSuccess] = useState(false);
    const [note, setItemNotes] = useState(null);
    const [banks,setBanks]= useState([]);
    const [bankcode,setBank]= useState([]);


    const handleBank = (event) => {
        // console.log(event);
        // let inputValue = event.target.value;
        // console.log(inputValue);
        setBank(event);
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));
    
        if (pastedData < 0) {
            e.preventDefault();
        }
    };
    const openModal = () => {
      setIsModalOpen(true);
    };
    const closeModalSuccess = () => {
        navigate('/transactions')
        setIsModalSuccess(false);
    };
    const openModalSuccess = () => {
        setIsModalSuccess(true);
    };
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    // Function to handle PIN validation result
    const handlePinValidate = (isValid) => {
      if (isValid) {
        closeModal()
        console.log('PIN is correct');
        toast.success('Correct PIN');
        transferMopal();
        // Perform actions for correct PIN
      } else {
        toast.error('PIN is incorrect');
        // console.log('PIN is incorrect');
        // Perform actions for incorrect PIN
      }
    }; 

    const handlePinValidateext = (isValid) => {
      if (isValid) {
        closeModal()
        console.log('PIN is correct');
        toast.success('Correct PIN');
        // const datas ={
        //     accountno: accountno,
        //     bank_code:bankcode,
        //     amount:selectedAmount,
        //     note: note,
        //     accountname: response.account_name,

        // }
        transferExt(response.account_name);

        // console.log(datas);
        // transferMopal();
        // Perform actions for correct PIN
      } else {
        toast.error('PIN is incorrect');
        // console.log('PIN is incorrect');
        // Perform actions for incorrect PIN
      }
    };
     const handleSucees = (isValid) => {
      if (isValid) {
        // closeModal()
        // console.log('PIN is correct');
        // toast.success('Correct PIN');
        // transferMopal();
        // Perform actions for correct PIN
      } else {
        toast.error('PIN is incorrect');
        // console.log('PIN is incorrect');
        // Perform actions for incorrect PIN
      }
    };
    const openMopaluser =() =>{
        setIsmoPaluser(true);
        setIsBankuser(false);
    }
    const openBankUser =() =>{
        setIsmoPaluser(false);
        setIsBankuser(true);
    }



    const [selectedAmount, setSelectedAmount] = useState(null);
    const handleItemClick = (value) => {
        setSelectedItem(value === selectedItem ? null : value);
        console.log(`${value}`);
    };
   
    const handleItemChange = (event) => {
        let inputValue = event.target.value;
        // // Disallow first zero input
        // if (inputValue.startsWith('0') && inputValue.length > 1) {
        //     inputValue = inputValue.slice(1);
        // }
        // if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
        // }
        setItemNotes(inputValue);
    };
    
    const handleAmountClick = (value) => {
        if (value < 0) return; // Prevent negative values
        setSelectedAmount(value);
        const formattedAmount = `₦${parseFloat(value).toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
        document.getElementById('amount').value = formattedAmount;
    };
   
    const handleAmountChange = (event) => {
        let inputValue = event.target.value;
        
        // Disallow first zero input
        if (inputValue.startsWith('0') && inputValue.length > 1) {
            inputValue = inputValue.slice(1);
        }
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setSelectedAmount(inputValue);
        }
    };
     const handlePhoneChange = (event) => {
        let inputValue = event.target.value;
        console.log(inputValue.length);
        if(inputValue.length  > 10 || inputValue.length === 11){
            setPhonenumber(inputValue);
            validatePhoneNumber(inputValue)
        }
    };


    const formatCurrency = (value) => {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) return '';
        return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    };

    // const handleAmountChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') { // Allow only numeric input or empty string
    //         setSelectedAmount(formatCurrency(inputValue)); // Format the input value
    //     }
    // };

    // const formatCurrency = (value) => {
    //     const floatValue = parseFloat(value);
    //     if (isNaN(floatValue)) return ''; // If value is not a valid number, return empty string
    //     return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    // };
    const validateMeterNumber = async () => {
        setLoading(true)
    try {
        console.log(user.id)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/validatevehicle/${user.id}/${phone_number}`
        
        );
        // console.log(response.data.data.data);
        setResponse(response.data.data.data);
        stopLoading();
        setvButton(true)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const getBanks = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transfer/listallbank`,
         
             );
        // console.log(response.data.data);
        setBanks(response.data.data);
        stopLoading();
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}
const handleAccNoChange = (event) => {
    let inputValue = event.target.value;
    // console.log(inputValue.length);
    if(inputValue.length  > 9 || inputValue.length === 10){
        setAccountNo(inputValue);
        validateAccountNo(inputValue);
    }
};
const validateAccountNo = async (inputValue) => {
    try {
        setLoading(true)
        // const token = localStorage.getItem('lpin'); 
        // console.log(token);
        // const headers = {
        //   'x-access-token-afripayu': `Bearer ${token}`,
        // };
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/transfer/verifyaccnumber`,
      {
        bank_code: bankcode,
         account_number: inputValue
        }
    );
      const data = response.data.data;
      console.log(data);
    //   setReceiverID(data.id)
      toast.success(data.message);
      setResponse(data);
      stopLoading();
     setvButton(true)
     
    } catch (error) {
     
    } finally {
        // setLoading(false); // Set loading state to false after fetching data
      }
  };
const transferMopal = async () => {
    try {
        setLoading(true)
        console.log(receiverid)
        console.log('ereach herer')
        const datas =  { 
            rid:receiverid,
            amount:selectedAmount,
            payment_method:'Mo Transfer',
            note:note
        }
        console.log(datas);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/transfer/create`,
        { 
            rid:receiverid,
            amount:selectedAmount,
            payment_method:'Mo Transfer',
            note:note        
        }
    );
      const data = response.data;
      console.log(data);
    //   setResponse(response.data.data);
    const success = data.success;
    const mssg = data.message;
        if(success){
            openModalSuccess();
            toast.success(mssg);
            // navigate('/transactions')
        }else{
            console.log(data.message);
          toast.warning(mssg);
        }
      stopLoading();
      setvButton(true)
    } catch (error) {
        console.log(error)
    } finally {
        // setLoading(false); // Set loading state to false after fetching data
      }
  };
  const transferExt = async (account_name) => {
    try {
        setLoading(true)
        // console.log('receiverid')
        // console.log('ereach ext herer')
        // console.log(accountno)
        // console.log(bankcode)
        // console.log(selectedAmount)
        // console.log(note)
        // console.log(account_name)
       
        var datas ={
            accountno: accountno,
            bank_code:bankcode,
            amount:selectedAmount,
            note: note,
            accountname: account_name,

        }
        // console.log(datas);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/transferext`,
        
        datas
    );
      const data = response.data;
      console.log(data);
    //   setResponse(response.data.data);
    const success = data.success;
    const mssg = data.message;
        if(success){
            openModalSuccess();
            toast.success(mssg);
            // navigate('/transactions')
        }else{
            // console.log(data.message);
            toast.warning(mssg);
        }
        stopLoading();
        setvButton(true)
    } catch (error) {
        stopLoading();
        setvButton(true)
        toast.warning('Poor Network');
        
        console.log(error)
    } finally {
        // setLoading(false); // Set loading state to false after fetching data
      }
  };

const validatePhoneNumber = async (inputValue) => {
    try {
        setLoading(true)
        // const token = localStorage.getItem('lpin'); 
        // console.log(token);
        // const headers = {
        //   'x-access-token-afripayu': `Bearer ${token}`,
        // };
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/transfer/verifyaccphonenumber/${inputValue}`,
    //   { headers }
    );
      const data = response.data.data;
    //   console.log(data.id);
      setReceiverID(data.id)
      setResponse(response.data.data);
      stopLoading();
        setvButton(true)
     
    } catch (error) {
     
    } finally {
        // setLoading(false); // Set loading state to false after fetching data
      }
  };

useEffect(() => {
    setIsBankuser(true);
    getBanks();
    const token = localStorage.getItem('lpin').replace(/"/g, '');

    axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
}, []);


  return (
   
     <section className="mainBodyContainer">
            <div className="container-fluid dashboardSummary">
              <div className="row">
                    <div className="col-sm-12">
                        <div className="materialCard materialTableContainer mt-5">
 
                            <div className="flex-between" style={{ }}>
                              
                            <div className="materialCardForm" style={{width: '100%'}}>
                                    <div className="">
                                        <div className="px-5 ml-5">
                                        <div className="card-body">
                                            
                                        {/* <form onSubmit={}> */}
							                {/* <div className="form-group">
                                            {/* <label for="network">Select network provider</label> */}
                                           {/* <div className="scrolling-wrapper">

                                            <div className="btn-group materialButtonDropdown ml-4">
                                                    {/* <div></div> */}
                                                    {/* <button type="button" className=" ml-4 orange-theme btn-sm mr-5 " onClick={openBankUser}>
                                                        Transfer to  Other Bank
                                                    </button>
                                                    <button type="button " className="  btn-sm mr-2" onClick={openMopaluser}>
                                                    Transfer to MoPal User
                                                    </button>
                                            </div>
                                            </div>  
                                        </div> */}
                                        <div className="row">
                    <div className="col-sm-12">
                        <div className="materialTabButton">
                            <ul className="nav nav-pills nav-fill">
                                <li className="nav-item" onClick={openBankUser}>
                                    <a className={`nav-link ${isBankUser ? "active": ''} `} aria-current="page" >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 7.16C17.94 7.15 17.87 7.15 17.81 7.16C16.43 7.11 15.33 5.98 15.33 4.58C15.33 3.15 16.48 2 17.91 2C19.34 2 20.49 3.16 20.49 4.58C20.48 5.98 19.38 7.11 18 7.16Z" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.9699 14.44C18.3399 14.67 19.8499 14.43 20.9099 13.72C22.3199 12.78 22.3199 11.24 20.9099 10.3C19.8399 9.59004 18.3099 9.35003 16.9399 9.59003" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.96998 7.16C6.02998 7.15 6.09998 7.15 6.15998 7.16C7.53998 7.11 8.63998 5.98 8.63998 4.58C8.63998 3.15 7.48998 2 6.05998 2C4.62998 2 3.47998 3.16 3.47998 4.58C3.48998 5.98 4.58998 7.11 5.96998 7.16Z" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.99994 14.44C5.62994 14.67 4.11994 14.43 3.05994 13.72C1.64994 12.78 1.64994 11.24 3.05994 10.3C4.12994 9.59004 5.65994 9.35003 7.02994 9.59003" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 14.63C11.94 14.62 11.87 14.62 11.81 14.63C10.43 14.58 9.32996 13.45 9.32996 12.05C9.32996 10.62 10.48 9.46997 11.91 9.46997C13.34 9.46997 14.49 10.63 14.49 12.05C14.48 13.45 13.38 14.59 12 14.63Z" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.08997 17.78C7.67997 18.72 7.67997 20.26 9.08997 21.2C10.69 22.27 13.31 22.27 14.91 21.2C16.32 20.26 16.32 18.72 14.91 17.78C13.32 16.72 10.69 16.72 9.08997 17.78Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        Other Banks
                                    </a>
                                </li>
                                <li className="nav-item" onClick={openMopaluser}>
                                  <a className={`nav-link ${ismoPaluser ? "active": ''} `} >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 5.56006H22" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.22 2H19.78C21.56 2 22 2.44 22 4.2V8.31C22 10.07 21.56 10.51 19.78 10.51H14.22C12.44 10.51 12 10.07 12 8.31V4.2C12 2.44 12.44 2 14.22 2Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 17.0601H12" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.22 13.5H9.78C11.56 13.5 12 13.94 12 15.7V19.81C12 21.57 11.56 22.01 9.78 22.01H4.22C2.44 22.01 2 21.57 2 19.81V15.7C2 13.94 2.44 13.5 4.22 13.5Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 9C2 5.13 5.13 2 9 2L7.95001 3.75" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                      Mopal User
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                            {ismoPaluser ?

                            <div>
							<div className="form-group">
								<label>Phone number</label>
                                <input 
                                        placeholder="Enter Phone Number" 
                                        name="phone" 
                                        id="phone" 
                                        className="form-control" 
                                        maxLength={11} 
                                        onChange={handlePhoneChange}
                                    />
							</div>
							   <div className="form-group">
                                        <label>Amount</label>
                                        <div className="scrolling-wrapper my-2">
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '100' ? 'selected' : ''}`} data-amt="100" onClick={() => handleAmountClick('100')}>
                                                <span className="network-name">₦100</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '200' ? 'selected' : ''}`} data-amt="200" onClick={() => handleAmountClick('200')}>
                                                <span className="network-name">₦200</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '500' ? 'selected' : ''}`} data-amt="500" onClick={() => handleAmountClick('500')}>
                                                <span className="network-name">₦500</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '1000' ? 'selected' : ''}`} data-amt="1000" onClick={() => handleAmountClick('1000')}>
                                                <span className="network-name">₦1,000</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '5000' ? 'selected' : ''}`} data-amt="5000" onClick={() => handleAmountClick('5000')}>
                                                <span className="network-name">₦5,000</span>
                                            </div>
                                        </div>
                                        {/* <input className="form-control" type="text" name="amount" id="amount" min="0" placeholder="Enter The Amount" /> */}
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="amount"
                                            id="amount"
                                            value={selectedAmount}
                                            onChange={handleAmountChange}
                                            placeholder="Enter The Amount"
                                            onPaste={preventPasteNegative}
                                            onKeyPress={preventMinus}
                                        />
                                    </div>
                            <input type="hidden" name="network" id="network" value="9mobile"/>
							<input type="hidden" name="postType" value="single"/>
							<input type="hidden" name="discount" id="discount" value="3"/>
							<input type="hidden" name="network_id" id="network_id" value="23"/>
                            {loading ? <Spinner /> : (
                                    response && (
                                        <div className="form-row">
                                            <div className="form-group col-md-6 col-12">
                                                <label for="name">First Name</label>
                                                <input className="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerName" id="customerName" value={response.first_name}/>
                                            </div>

                                            <div className="form-group col-md-6 col-12">
                                                <label for="name"> Last Name</label>
                                                <input className="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerInfo" id="customerInfo" value={response.last_name}/>
                                                <input className="form-control" type="hidden" readOnly name="product_code" id="product_code" value={response.id} />
                                                {response?.profile_pic ? (
                                                    <Avatar src={`${baseURL + picture + response.profile_pic.toString()}`} alt="profile_pic" />
                                                ) : (
                                                    <ProfileAvatar />
                                                )}
                                            </div>
                                            {/* <img className="" src={``}/> */}
                                            <div className="form-group col-md-12 col-12">
                                                <label for="name">Notes </label>
                                                <textarea className="form-control" type="text" placeholder="Note or Reason" name="customerName" id="customerName" onChange={handleItemChange} />
                                            </div>
                                          
                                        </div>
                                    )
                                )}
                            {vbutton ?
                                // <input type="button" onClick={validateMeterNumber} className="btn-primary ctn col-sm-12" value={'PROCEED'}/>
                                <div>
                                <input type="button" onClick={openModal} className="btn-primary ctn col-sm-12" value={'PROCEED'}/>
                                <PinModal isOpen={isModalOpen} onClose={closeModal} onPinValidate={handlePinValidate} />
                                <ScuccessModal isOpen={isModalOpenSuccess} onClose={closeModalSuccess} onPinValidate={handleSucees} />

                            </div>
                                :
                                <input type="button" onClick={validateMeterNumber} className="btn-primary ctn col-sm-12" value={'VALIDATE'}/>
                            }
 							{/* </form>						 */}
                            </div>
                            :
                            <div>
							<div className="form-group">
                            <label for="banks">Which bank?</label>

                            {/* <select onChange={(e) => handleBank(e.target.value)}>
                                {options.map((item) => (
                                    <option key={item.value} value={item.value}>
                                    {item.name}
                                    </option>
                                ))}
                                </select> */}

								<select onChange={(e) => handleBank(e.target.value)} class="form-control mb-2 " name="month">
									<option className='mb-4' value="" selected="">Select Bank</option>
                                     {banks.length > 0 ? (
                                        banks.map((item, index) => (
                                            <option key={item.value} value={item.code}>{item.name}</option>
                                        ))
                                    ) : (
                                        <p></p>
                                    )}
								</select>
								<label>Account Number</label>
                                <input 
                                        placeholder="Enter Receipient  Account Number" 
                                        name="phone" 
                                        id="phone" 
                                        className="form-control" 
                                        maxLength={10} 
                                        onChange={handleAccNoChange} 
                                    />
							</div>
							   <div className="form-group">
                                        <label>Amount</label>
                                        <div className="scrolling-wrapper my-2">
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '100' ? 'selected' : ''}`} data-amt="100" onClick={() => handleAmountClick('100')}>
                                                <span className="network-name">₦100</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '200' ? 'selected' : ''}`} data-amt="200" onClick={() => handleAmountClick('200')}>
                                                <span className="network-name">₦200</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '500' ? 'selected' : ''}`} data-amt="500" onClick={() => handleAmountClick('500')}>
                                                <span className="network-name">₦500</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '1000' ? 'selected' : ''}`} data-amt="1000" onClick={() => handleAmountClick('1000')}>
                                                <span className="network-name">₦1,000</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '5000' ? 'selected' : ''}`} data-amt="5000" onClick={() => handleAmountClick('5000')}>
                                                <span className="network-name">₦5,000</span>
                                            </div>
                                        </div>
                                        {/* <input className="form-control" type="text" name="amount" id="amount" min="0" placeholder="Enter The Amount" /> */}
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="amount"
                                            id="amount"
                                            value={selectedAmount}
                                            onChange={handleAmountChange}
                                            placeholder="Enter The Amount"
                                        />
                                    </div>
                           
                            {loading ? <Spinner /> : (
                                    response && (
                                        <div className="form-row">
                                            <div className="form-group col-md-6 col-12">
                                                <label for="name"> Name</label>
                                                <input className="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerName" id="customerName" value={response.account_name}/>
                                            </div>

                                            <div className="form-group col-md-6 col-12">
                                                <label for="name"> Address</label>
                                                <input className="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerInfo" id="customerInfo" value={accountno}/>
                                                {/* <input className="form-control" type="hidden" readOnly name="product_code" id="product_code" value={response.productCode} /> */}
                                            </div>
                                        </div>
                                    )
                                )}
                            {vbutton ?
                            <div>
                                   <input type="button" onClick={openModal} className="btn-primary ctn col-sm-12" value={'PROCEED'}/> 
                                   <PinModal isOpen={isModalOpen} onClose={closeModal} onPinValidate={handlePinValidateext} />
                                <ScuccessModal isOpen={isModalOpenSuccess} onClose={closeModalSuccess} onPinValidate={handleSucees} />
                            </div>
                                   
                                    // <div>
                                    // <button onClick={openModal}>Open Modal</button>
    //   {/* <PinModal isOpen={isModalOpen} onClose={closeModal} onPinValidate={handlePinValidate} />
    
    //                             </div> */}
                                :
                                <input type="button"  className="btn-primary ctn col-sm-12" value={'VALIDATE'}/>
                            }
 							{/* </form>						 */}
                            </div>
                            }
                            </div>

                             
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>

                             
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
          
  )
}
 