import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import PinInput from "react-pin-input";
import { toast } from "react-toastify";
import { decryptData } from "../../../utils/util";

export const PinModal = ({
  isPin,
  handleIsPinClose,
  pinErrorLabel,
  pinValue,
  onChn,
  onSuc,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  let user = null;
  // Check if localStorage item exists and decrypt it to obtain 'user' data
  const mkLocalData = localStorage.getItem("ISONU_OGBON_AWE");
  if (mkLocalData) {
    const salt = "6d090796-ecdf-11ea-adc1-0242ac112345";
    const originalData = decryptData(mkLocalData, salt);
    user = JSON.parse(originalData);
  }

  console.log("USwr", user);
  return (
    <Modal
      open={isPin}
      onClose={handleIsPinClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {pinErrorLabel ? pinErrorLabel : "Enter your transaction pin"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PinInput
            length={4}
            initialValue={pinValue}
            secret
            secretDelay={100}
            onChange={(value, index) => {
              onChn(value, index);
            }}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px" }}
            inputStyle={{ borderColor: "black" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={(value, index) => {
              const enteredPin = parseInt(value, 10);
              if (user && enteredPin !== user?.pin) {
                toast.error("Incorrect pin");
              } else {
                toast.success("Pin correct");
                onSuc();
              }
            }}
            focus={true}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </Box>
      </Box>
    </Modal>
  );
};
