import React from "react";
import Select from "react-select";

export const CustomSelect = ({
  options,
  value,
  onChange,
  label,
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
}) => {
  const formattedOptions = options.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided, 
      height: "50px",
      border: "1.5px solid #D0D5DD",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "40px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
  };

  //   console.log("formattedOptions", formattedOptions);

  return (
    <div>
      <label className=" font-medium text-base">{label}</label>
      <Select
        id={formattedOptions.value}
        options={formattedOptions}
        value={value}
        onChange={onChange}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        styles={customStyles}
      />
    </div>
  );
};
