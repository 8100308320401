import {
  faBars,
  faBolt,
  faMobileScreenButton,
  faMoneyBill,
  faQuoteLeft,
  faShieldHalved,
  faStar,
  faStarHalf,
  faTowerBroadcast,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// import { MenuIcon } from "../Icons";
// import ScrollReveal from "scrollreveal";

const Home = () => {
  const smoothScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const navigate = useNavigate();

  return (
    <div className=" px-[5vw]">
      <header className="w-full flex justify-between py-6 items-center">
        <div className=" max-w-[70px] max-h-[70px]">
          <img src="https://getmopal.com/assets/images/mopal-logo.png" alt="" />
        </div>
        <nav className="header_links tablet:flex gap-[30px] hidden">
          <a href="#home" onClick={(e) => smoothScroll(e, "home")}>
            Home
          </a>
          <a href="#about" onClick={(e) => smoothScroll(e, "about")}>
            About Us
          </a>
          <a href="#services" onClick={(e) => smoothScroll(e, "services")}>
            Services
          </a>
          <a href="#for-who" onClick={(e) => smoothScroll(e, "for-who")}>
            For Who
          </a>
          <a
            href="#testimonial"
            onClick={(e) => smoothScroll(e, "testimonial")}
          >
            FAQs
          </a>
        </nav>
        <div
          onClick={() => {
            navigate("/login");
          }}
          className=" hidden tablet:block bg-[#009897] px-5 py-[10px] rounded-[20px] text-white text-sm"
        >
          Get Started
        </div>
        <div className=" block tablet:hidden">
          <Button onClick={toggleDrawer(true)} className=" w-[50px] h-[50px]">
            <FontAwesomeIcon
              icon={faBars}
              className=" text-black text-[35px]"
            />
          </Button>
          <Drawer
            anchor={"left"}
            open={isOpen}
            onClose={toggleDrawer(false)}
            className=""
          >
            <div className=" min-w-[200px] py-4 px-8">
              <div className=" max-w-[70px] max-h-[70px] mb-4">
                <img
                  src="https://getmopal.com/assets/images/mopal-logo.png"
                  alt=""
                />
              </div>
              <div>
                <nav className="header_links flex flex-col gap-[30px] tablet:hidden">
                  <a href="#home" onClick={(e) => smoothScroll(e, "home")}>
                    Home
                  </a>
                  <a href="#about" onClick={(e) => smoothScroll(e, "about")}>
                    About Us
                  </a>
                  <a
                    href="#services"
                    onClick={(e) => smoothScroll(e, "services")}
                  >
                    Services
                  </a>
                  <a
                    href="#for-who"
                    onClick={(e) => smoothScroll(e, "for-who")}
                  >
                    For Who
                  </a>
                  <a
                    href="#testimonial"
                    onClick={(e) => smoothScroll(e, "testimonial")}
                  >
                    FAQs
                  </a>
                </nav>
                <div
                  onClick={() => {
                    navigate("/login");
                  }}
                  className=" mt-6 block tablet:hidden bg-[#009897] px-5 py-[10px] rounded-[20px] text-white text-sm"
                >
                  Get Started
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </header>
      <div
        id="home"
        className="hero-section flex flex-col tablet:flex-row justify-between items-center w-full"
      >
        <div className="hero-text-section flex flex-col gap-5">
          <p className="hero_welcome_text text-[#020418] font-normal text-sm">
            WELCOME TO MOPAL MOBILE BANKING
          </p>
          <h1 className="hero_head_text text-[#020418] font-medium text-2xl tablet:text-[45px] tablet:leading-[45px]">
            Mopal Instant <br /> Payment Solutions For{" "}
            <br className=" hidden tablet:block" /> You
          </h1>
          <p className="hero_description_text text-[#56586e] font-[450] text-sm">
            Mopal takes pride in providing you with cutting-edge instant <br />{" "}
            payment solutions that carter to your financial needs.
          </p>
          <button className="hero_button bg-[#009897] px-[10px] py-[5px] rounded-[10px] text-white text-sm max-w-fit">
            Learn More
          </button>
          <div className="hero_icons flex gap-[25px]">
            <FontAwesomeIcon icon={faShieldHalved} className=" w-9 h-9" />
            <div className="hero-trans flex flex-col">
              <p className="hero-trans-text font-semibold">$ 20M+</p>
              <p>Transactions</p>
            </div>
            <div className="hero-trans flex flex-col">
              <p className="hero-trans-text font-semibold">1700+</p>
              <p>Active Users</p>
            </div>
          </div>
        </div>
        <div className="hero-image-section max-w-[100%] tablet:max-w-[50%]">
          <div className="hero-image-back relative max-w-[750px] max-h-[750px]">
            <img
              className="back-img"
              src="https://getmopal.com/assets/images/hero-back.png"
              alt=""
            />
            <img
              //   style={{ visibility: "hidden" }}
              className="mob-app absolute top-0 left-[50%] translate-x-[-50%] max-w-[500px] max-h-[500px] w-full h-full"
              src="https://getmopal.com/assets/images/mob-app.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="process_container bg-[#f7f7f7] my-8 min-h-[50vh] w-full flex justify-center items-center">
        <div className="process_content flex justify-center items-center">
          <div className="process-top flex flex-col justify-center items-center ">
            <div className="process_how text-sm flex flex-col items-center justify-center text-center gap-2">
              <p className=" text-sm ">PROCESS</p>
              <h1 className="process_how_bold text-base font-bold ">
                How it works
              </h1>
            </div>
            <div className="process_grid max-w-[940px] w-full flex flex-wrap justify-center tablet:justify-between">
              <div className="process_column max-w-[200px] w-full flex flex-col text-center gap-2 mt-8 justify-center items-center">
                <p className="process_num text-center bg-[#020626] h-[30px] w-[30px] rounded-full text-white flex items-center justify-center text-sm mb-2">
                  1
                </p>
                <h2 className=" font-medium text-xl">Register</h2>
                <p className="process_descripo]tion">
                  Register on mopal as a User or Business.
                </p>
              </div>
              <div className="process_column max-w-[200px] w-full flex flex-col text-center gap-2 mt-8 justify-center items-center">
                <p className="process_num text-center bg-[#020626] h-[30px] w-[30px] rounded-full text-white flex items-center justify-center text-sm mb-2">
                  2
                </p>
                <h2 className=" font-medium text-xl">Verification</h2>
                <p className="process_descripo]tion">
                  After Registration, verify your name and phone number to
                  proceed.
                </p>
              </div>
              <div className="process_column max-w-[200px] w-full flex flex-col text-center gap-2 mt-8 justify-center items-center">
                <p className="process_num text-center bg-[#020626] h-[30px] w-[30px] rounded-full text-white flex items-center justify-center text-sm mb-2">
                  3
                </p>
                <h2 className=" font-medium text-xl">Success</h2>
                <p className="process_descripo]tion">
                  You now have full access to Mopal and all its facilities
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="approach-section min-h-[50vh] w-full flex flex-col tablet:flex-row justify-center tablet:justify-between items-center bg-[#f7f7f7] my-8">
        <div className="approach_images max-w-[100%] tablet:max-w-[50%] w-full flex flex-col items-center ipad:flex-row gap-5 ">
          <div className="approach_first">
            <img
              src="https://getmopal.com/assets/images/approach-first.png"
              alt=""
              className="max-w-[270px] max-h-[270px] rounded-[10px]"
            />
          </div>
          <div className="approach_second ">
            <img
              src="https://getmopal.com/assets/images/approach-second.png"
              alt=""
              className="max-w-[270px] max-h-[270px] rounded-[10px]"
            />
          </div>
        </div>
        <div
          id="about"
          className="approach_texts_container max-w-[100%] tablet:max-w-[50%] w-full"
        >
          <h1 className="approach_heading font-[550] text-2xl tablet:text-4xl mt-6 tablet:mt-0">
            The Effortless Approach to <br /> Financial Transactions
          </h1>
          <p className=" mt-6 text-sm">
            Simplifying your financial transactions has never been easier. Our
            approach to payment is designed to make your life hassle-free,
            ensuring that every transaction is smooth and effortless
          </p>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="approach_button bg-[#009897] rounded-full text-white py-[5px] px-[10px] mt-4"
          >
            Get Started
          </button>
        </div>
      </section>
      <section
        id="services"
        className="explore_section flex flex-col tablet:flex-row justify-center tablet:justify-between items-center min-h-[50vh]"
      >
        <div className="explore_texts max-w-[100%] tablet:max-w-[50%] w-full">
          <h1 className=" font-bold text-2xl">
            Explore Our Banking Services and Benefits
          </h1>
          <p className=" mt-6 text-sm">
            Simplifying your financial transactions has never been easier. Our
            approach to payment is designed to make your life hassle-free,
            ensuring that every transaction is smooth and effortless
          </p>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="approach_button bg-[#009897] rounded-full text-white py-[5px] px-[10px] mt-4"
          >
            Get Started
          </button>
        </div>
        <div className="explore_tabs max-w-[100%] tablet:max-w-[50%] w-full grid grid-cols-2 gap-y-[20px] gap-x-2 tablet:gap-x-0 mt-6 tablet:mt-0">
          <div className="explore_tab bg-white flex flex-col justify-center items-center gap-5 rounded-[20px]  text-black max-w-[250px] max-h-[200px] w-full h-full py-6">
            <FontAwesomeIcon
              icon={faMoneyBill}
              className=" text-white bg-[#009898b9] rounded-[7px] p-[15px] text-base"
            />
            <p>Pay Bills</p>
          </div>
          <div className="explore_tab bg-white flex flex-col justify-center items-center gap-5 rounded-[20px]  text-black max-w-[250px] max-h-[200px] w-full h-full py-6">
            <FontAwesomeIcon
              icon={faTowerBroadcast}
              className=" text-white bg-[#009898b9] rounded-[7px] p-[15px] text-base"
            />
            <p>Cable TV</p>
          </div>
          <div className="explore_tab bg-white flex flex-col justify-center items-center gap-5 rounded-[20px]  text-black max-w-[250px] max-h-[200px] w-full h-full py-6">
            <FontAwesomeIcon
              icon={faMobileScreenButton}
              className=" text-white bg-[#009898b9] rounded-[7px] p-[15px] text-base"
            />
            <p>Airtime</p>
          </div>
          <div className="explore_tab bg-white flex flex-col justify-center items-center gap-5 rounded-[20px]  text-black max-w-[250px] max-h-[200px] w-full h-full py-6">
            <FontAwesomeIcon
              icon={faBolt}
              className=" text-white bg-[#009898b9] rounded-[7px] p-[15px] text-base"
            />
            <p>Electricity</p>
          </div>
        </div>
      </section>
      <section
        id="for-who"
        className="scalable_section min-h-[70vh] flex flex-col justify-center items-center text-center my-8 bg-white"
      >
        <h1 className="scalable_heading font-medium text-2xl tablet:text-4xl text-[#020418]">
          Scalable Solution for <br /> Business of Any Sizes
        </h1>
        <p className="scalable_texts text-sm max-w-[500px] w-full mx-auto mt-4">
          Simplifying your financial transactions has never been easier. Our
          approach to payment is designed to make your life hassle-free,
          ensuring that every transaction is smooth and effortless
        </p>
        <div className="scalable_grids grid grid-cols-1 tablet:grid-cols-3 gap-y-4 tablet:gap-y-0 gap-x-5 mt-6">
          <div className="scalable_box max-w-[350px] max-h-[350px] w-full h-full rounded-[12px] overflow-hidden relative ">
            <img
              src="https://getmopal.com/assets/images/mopal-users.jpg"
              alt=""
              className=" object-cover w-full h-full"
            />
            <div className="scalabale_box_bottom absolute bottom-0 left-0 w-full rounded-b-xl bg-[#ffffffb1] px-2 py-4 text-black text-lg font-medium text-left">
              <h1>For Personal Use</h1>
            </div>
          </div>
          <div className="scalable_box max-w-[350px] max-h-[350px] w-full h-full rounded-[12px] overflow-hidden relative ">
            <img
              src="https://getmopal.com/assets/images/mo-bus.png"
              alt=""
              className=" object-cover w-full h-full"
            />
            <div className="scalabale_box_bottom absolute bottom-0 left-0 w-full rounded-b-xl bg-[#ffffffb1] px-2 py-4 text-black text-lg font-medium text-left">
              <h1>For Small Business</h1>
            </div>
          </div>
          <div className="scalable_box max-w-[350px] max-h-[350px] w-full h-full rounded-[12px] overflow-hidden relative ">
            <img
              src="https://getmopal.com/assets/images/mo-ent.jpg"
              alt=""
              className=" object-cover w-full h-full"
            />
            <div className="scalabale_box_bottom absolute bottom-0 left-0 w-full rounded-b-xl bg-[#ffffffb1] px-2 py-4 text-black text-lg font-medium text-left">
              <h1>For Enterprise Solutions</h1>
            </div>
          </div>
        </div>
      </section>
      <section
        id="testimonial"
        className="testimonial_section bg-[#f7f7f7] py-[50px] px-6 tablet:px-[50px] my-8 flex flex-col justify-center items-center gap-6"
      >
        <h1
          style={{ width: "500px" }}
          className="scalable_heading font-medium text-2xl tablet:text-4xl text-[#020418] text-center"
        >
          Satisfied Customers Share <br className=" tablet:hidden" /> Their
          Thoughts on Mopal
        </h1>
        <p className="scalable_texts max-w-[600px] w-full text-center mx-auto text-base text-[#020418] ">
          Our valued customers have been generous in sharing their thoughts
          about our company, and we're thrilled to have such satisfied clients.
          Their testimonials serve as a testament to the quality of our services
          and the dedication of our team
        </p>

        <div className="testimonial_slide grid grid-cols-1 tablet:grid-cols-2 gap-y-4 tablet:gap-y-0 gap-x-5">
          <div className="testimonial_tabs flex flex-col gap-5 rounded-xl bg-white px-5 tablet:px-9 py-6 max-w-[450px] w-full">
            <FontAwesomeIcon
              icon={faQuoteLeft}
              className=" text-[50px] text-[#020626]"
            />
            <p style={{ color: "#3c3e57" }}>
              “It's the way forward, and | can't imagine going back to
              traditional banking methods. | highly recommend it to anyone
              looking for a modern and convenient way to manage their finances"
            </p>
            <div className="testimonial_stars">
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStarHalf} className=" text-[#009897]" />
            </div>
            <div className="testimonial_profile flex gap-2 items-center">
              <div className="testimonial_profile_image max-w-[50px] max-h-[50px] rounded-full overflow-hidden w-full h-full">
                <img
                  src="https://getmopal.com/assets/images/profile.avif"
                  alt=""
                  className=" object-cover w-full h-full"
                />
              </div>
              <div className="testimonial_profile_name">
                <h1>Mike Tom</h1>
                <p>UI/UX Designer</p>
              </div>
            </div>
          </div>
          <div className="testimonial_tabs flex flex-col gap-5 rounded-xl bg-white px-5 tablet:px-9 py-6 max-w-[450px] w-full">
            <FontAwesomeIcon
              icon={faQuoteLeft}
              className=" text-[50px] text-[#020626]"
            />
            <p style={{ color: "#3c3e57" }}>
              "Another great aspect is the 24/7 customer support. I've had a few
              questions and issues along the way, and the digital banking
              customer service team has always been responsive and helpful”
            </p>
            <div className="testimonial_stars">
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStar} className=" text-[#009897]" />
              <FontAwesomeIcon icon={faStarHalf} className=" text-[#009897]" />
            </div>
            <div className="testimonial_profile flex gap-2 items-center">
              <div className="testimonial_profile_image max-w-[50px] max-h-[50px] rounded-full overflow-hidden w-full h-full">
                <img
                  src="https://getmopal.com/assets/images/profile-2.avif"
                  alt=""
                  className=" object-cover w-full h-full"
                />
              </div>
              <div className="testimonial_profile_name">
                <h1>Claudia Dina</h1>
                <p>Entreprenuer</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-section flex flex-col tablet:flex-row justify-start tablet:justify-between gap-y-2 tablet:gap-y-0 tablet:items-center my-6 py-[50px]">
        <div>
          <div className="footer_image max-w-[70px] w-full max-h-[70px] h-full ">
            <img
              src="https://getmopal.com/assets/images/mopal-logo.png"
              alt=""
            />
          </div>
          <p style={{ width: "200px" }} className=" mt-2 tablet:mt-0">
            We take great pride in providing top-notch solutions
          </p>
        </div>
        <div className="footer_for_who mt-3 tablet:mt-0">
          <h2 className=" font-semibold text-lg tablet:text-2xl text-[#020418] tracking-wide">
            For Who
          </h2>
          <ul className=" flex flex-col gap-[5px] tablet:gap-[10px] ml-2 tablet:ml-4 mt-2">
            <li>Personal Use</li>
            <li>Small Business</li>
            <li>Enterprise Solutions</li>
          </ul>
        </div>
        <div className="footer_for_who">
          <h2 className=" font-semibold text-lg tablet:text-2xl text-[#020418] tracking-wide">
            About Us
          </h2>
          <ul className=" flex flex-col gap-[5px] tablet:gap-[10px] ml-2 tablet:ml-4 mt-2">
            <li>Career</li>
            <li>Our Team</li>
            <a
              style={{ textDecoration: "none", color: "#2c3e50" }}
              href="https://getmopal.com/privacy-policy"
            >
              Privacy Policy
            </a>
          </ul>
        </div>
        <div className="footer_for_who">
          <h2 className=" font-semibold text-lg tablet:text-2xl text-[#020418] tracking-wide">
            Contact Us
          </h2>
          <ul className=" flex flex-col gap-[5px] tablet:gap-[10px] ml-2 tablet:ml-4 mt-2">
            <li>Contact Form</li>
            <li>Informations</li>
            <li>Customer Services</li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Home;
