import React from 'react';
import { useSelector } from 'react-redux';
import { AdminDashboard } from './AdminDasboard';
import { PersonalAccount } from './PersonalAccount';

export const IndexDashboard = () => {
    const auth = useSelector((state) => state.auth);
    
    // console.log(auth)
    const userAccountType = auth?.user?.account_type;

    let dashboard = null;

    if (userAccountType === 2) {
        dashboard = <AdminDashboard />;
    } else if (userAccountType === 1) {
        dashboard = <PersonalAccount />;
    } else {
        // Default dashboard or handle other account types
        dashboard = <AdminDashboard />;
    }

    return dashboard;
};
