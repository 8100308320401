import React, { useState } from 'react';
import CustomModal from './Modal/makepaymentModal';
 import { decryptData } from '../utils/util';
 import success from '../images/assets/success.gif';
 import useWindowSize from 'react-use/lib/useWindowSize'
 import Confetti from 'react-confetti'

const ScuccessModal = ({ isOpen, onClose, onPinValidate }) => {
  let user = null;
  // Check if localStorage item exists and decrypt it to obtain 'user' data
  const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
  if (mkLocalData) {
      const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
      const originalData = decryptData(mkLocalData, salt);
      user = JSON.parse(originalData);
  }
  // console.log(user);
  const [pin, setPin] = useState('');

  // Function to handle PIN input change
  const handlePinChange = (value) => {
    setPin(value);
    // console.log(`value: ${value}`);
  };

  // Function to handle PIN form submission
  const handleSubmit = (value, index) => {
    const isValid = validatePin(value);
    onPinValidate(isValid);
  };

  // Function to validate the entered PIN (example logic)
  const validatePin = (enteredPin) => {
    const correctPin = JSON.stringify(user.pin);
    return enteredPin === correctPin;
  };
  
  const { width, height } = useWindowSize()

  return (
  <CustomModal isOpen={isOpen} onClose={onClose} className="materialCard">
   <div className={''}>
      <div className="modal-dialog">
     
          </div>
          <div className="modal-body">
          
              <Confetti
                width={width}
                height={height}
              />
              <div className="">
              <div class="materialModalHeader">
            <div class="closeModal" onClick={onClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1.5L22.8627 22.5627" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.8625 1.5L1.49986 22.5627" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </div>
            </div>
                {/* <label htmlFor="pinInput" className="form-label text-center">Enter PIN</label> */}
                {/* <input type="password" className="form-control" id="pinInput" value={pin} onChange={handlePinChange} /> */}
                <img className="" src={success}/>
                <h2 className=' text-center'>Transaction Successful</h2>
                            <p className=' text-center'>Your transaction has been successful.</p>
                            {/* <p>You will be </p> */}
              </div>
                {/* <PinField ref={defaultProps} />; */}
              {/* <button type="submit" className="btn btn-primary">Submit</button> */}
           
          {/* </div>
        </div> */}
      </div>
    </div>
    </CustomModal>
  );
};

export default ScuccessModal;
