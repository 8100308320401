import React from "react";

export const PredefinedAmount = ({
  amount,
  selectedAmount,
  price,
  onclick,
}) => {
  return (
    <div
      className={`amount-item justify-content-center border-1 ${
        selectedAmount === amount ? "selected" : ""
      }`}
      data-amt={amount}
      onClick={onclick}
    >
      <span className="network-name">{price}</span>
    </div>
  );
};
