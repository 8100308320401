import axios from "axios";

const axiosPostRequest = async (url, requestData, requestHeaders) => {
  try {
    const response = await axios.post(url, requestData, {
      headers: requestHeaders,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default axiosPostRequest;
