export const NotificationIcon = ({onclick}) => {
  return (
    <svg
    onClick={onclick}
      className="notification"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.3755 16.5765C20.5348 15.678 18.9612 14.3266 18.9612 9.90014C18.9627 8.31165 18.4072 6.7723 17.3904 5.54694C16.3735 4.32158 14.9589 3.48682 13.3898 3.1863V2.28476C13.3898 2.10293 13.3538 1.92288 13.2838 1.75489C13.2138 1.5869 13.1112 1.43426 12.9818 1.30569C12.8525 1.17712 12.6989 1.07513 12.5299 1.00554C12.361 0.935961 12.1798 0.900146 11.9969 0.900146C11.814 0.900146 11.6329 0.935961 11.4639 1.00554C11.2949 1.07513 11.1414 1.17712 11.012 1.30569C10.8827 1.43426 10.7801 1.5869 10.7101 1.75489C10.6401 1.92288 10.6041 2.10293 10.6041 2.28476V3.1863C9.03499 3.48682 7.62033 4.32158 6.60347 5.54694C5.58662 6.7723 5.03117 8.31165 5.03267 9.90014C5.03267 14.3266 3.45906 15.678 2.6184 16.5765C2.37861 16.8307 2.24668 17.167 2.25006 17.5155C2.25023 17.6977 2.28651 17.878 2.35683 18.0462C2.42716 18.2144 2.53015 18.3672 2.65991 18.4958C2.78968 18.6244 2.94367 18.7263 3.11309 18.7957C3.2825 18.865 3.46402 18.9005 3.64725 18.9001H20.3528C20.536 18.9005 20.7175 18.865 20.887 18.7957C21.0564 18.7263 21.2104 18.6244 21.3401 18.4958C21.4699 18.3672 21.5729 18.2144 21.6432 18.0462C21.7135 17.878 21.7498 17.6977 21.75 17.5155C21.7517 17.1662 21.6175 16.8297 21.3755 16.5765Z" />
      <path
        d="M10.5 1.65015H13.5V3.15015L12.75 3.97515H12H11.25L10.5 3.15015V1.65015Z"
        fill="#51526C"
      />
      <path
        d="M15 21C15 21.7956 14.6839 22.5587 14.1213 23.1213C13.5587 23.6839 12.7956 24 12 24C11.2044 24 10.4413 23.6839 9.87868 23.1213C9.31607 22.5587 9 21.7956 9 21L12 21H15Z"
        fill="#51526C"
      />
    </svg>
  );
};
