import axios from "axios";
import authHeader from "./auth-header";

// const fundUserWallet = () => {
//     return axios.get(`${process.env.REACT_APP_BASE_URL}//user/fundmwallet`,  { headers: authHeader() });
// };
const fundUserWallet = async (payload) => {
    console.log(payload);
    console.log('================================================================From Payload: ');
    const response = await axios
        .post(process.env.REACT_APP_BASE_URL + "/user/fundmwallet",
            payload,
            { headers: authHeader() }
        );
    return response;
};
const userWallet = {
    fundUserWallet, 
 
};

export default userWallet;