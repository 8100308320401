import React, { useState, useEffect, useRef } from 'react';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import Spinner from '../Spinner';
import { decryptData } from '../../../../utils/util';
 
export const ComingSoonPage = () => {
    let user = null;

// Check if localStorage item exists and decrypt it to obtain 'user' data
const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
if (mkLocalData) {
    const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
    const originalData = decryptData(mkLocalData, salt);
    user = JSON.parse(originalData);
}
    // let user = JSON.parse(localStorage.getItem("user"));
    // setInterval(() => {
    //     business = JSON.parse(localStorage.getItem("business"));
    // }, 1000);
    // function NetworkItems() {
    // }
    const [selectedItems, setSelectedItems] = useState([]);
    

    const [selectedItem, setSelectedItem] = useState(null);
    const [vbutton, setvButton] = useState(false);
    const [response, setResponse] = useState(null);
    const [phone_number, setPhonenumber] = useState(null);
    const stopLoading = () => setLoading(false);
    const [loading, setLoading] = useState(false);


    const handleItemClick = (value) => {
        setSelectedItem(value === selectedItem ? null : value);
        console.log(`${value}`);
    };
    const [selectedAmount, setSelectedAmount] = useState(null);
   

    
    const handleAmountClick = (value) => {
        if (value < 0) return; // Prevent negative values
        setSelectedAmount(value);
        const formattedAmount = `₦${parseFloat(value).toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
        document.getElementById('amount').value = formattedAmount;
    };
   
    const handleAmountChange = (event) => {
        let inputValue = event.target.value;
        
        // Disallow first zero input
        if (inputValue.startsWith('0') && inputValue.length > 1) {
            inputValue = inputValue.slice(1);
        }
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setSelectedAmount();
        }
    };
     const handlePhoneChange = (event) => {
        let inputValue = event.target.value;
        // Disallow first zero input
        // if (inputValue.startsWith('0') && inputValue.length > 1) {
        //     inputValue = inputValue.slice(1);
        // }
        // if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
        //     setSelectedAmount();
        // }
        setPhonenumber(inputValue);
    };


    const formatCurrency = (value) => {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) return '';
        return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    };

    // const handleAmountChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') { // Allow only numeric input or empty string
    //         setSelectedAmount(formatCurrency(inputValue)); // Format the input value
    //     }
    // };

    // const formatCurrency = (value) => {
    //     const floatValue = parseFloat(value);
    //     if (isNaN(floatValue)) return ''; // If value is not a valid number, return empty string
    //     return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    // };
    const validateMeterNumber = async () => {
        // console.log('Validating meter number:', meterNumber);
        setLoading(true)
    try {
        console.log(user.id)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/validatevehicle/${user.id}/${phone_number}`
        // ,{
            // meter_number: phone_number,
            // meter_type: selectedMeterTypeItem,
            // amount: selectedAmount,
            // service: selectedItem ,
            // }
        );
        console.log(response.data.data.data);
        setResponse(response.data.data.data);
        stopLoading();
        setvButton(true)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

useEffect(() => {
    const token = localStorage.getItem('lpin');
    axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
    // document.addEventListener('click', handleClickOutside);
    // return () => {
    //   document.removeEventListener('click', handleClickOutside);
    // };
}, []);
  return (
   
     <section className="mainBodyContainer">
            <div className="container-fluid dashboardSummary">
              <div className="row">
                    <div className="col-sm-12">
                        <div className="materialCard materialTableContainer mt-5">
 
                            <div className="flex-between" style={{ }}>
                              
                            <div className="materialCardForm" style={{width: '100%'}}>
                                    <div className="">
                                        <div className="px-5 ml-5">
                                        <div className="card-body">
                                            
                                        {/* <form onSubmit={}> */}
							         <div className="form-group">
                                            {/* <label for="network">Select network provider</label> */}
                                           <div className="scrolling-wrapper">
                                           
                                           </div>
                                     </div>
                                </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        
          
  )
}
