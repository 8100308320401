import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userWallet from '../../services/userWallet';
import { setMessage } from "./messages";


const initialState = {
    wallets: [],
    error: null,
    success: false,
}
 
export const updateUserWallet = createAsyncThunk(
    "/profile/update/userwallet",
    async (payload, thunkAPI) => {
        try {
            console.log('================================================================ just Wallet now reducer')
            const response = await userWallet.fundUserWallet(payload);

            console.log(response);
            thunkAPI.dispatch(setMessage(response.data));
            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


const userWalletSlice = createSlice({
    name: "/get",
    initialState,
    extraReducers: {
        [updateUserWallet.fulfilled]: (state, action) => {
            state.wallets = action.payload;
            state.success = true;
        },
        // [getCustomers.fulfilled]: (state, action) => {
        //     state.customers = action.payload;
        //     state.success = true;
        // },
        // [getPartners.fulfilled]: (state, action) => {
        //     state.partners = action.payload;
        //     state.success = true;
        // },
    },
});

const { reducer } = userWalletSlice;
export default reducer;