import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment'; // Import moment for date formatting

const TransactionChart = ({ data }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (data) {
      const ctx = document.getElementById('transactionChart');
      if (ctx) {
        // Extracting data from the response
        const crTransactions = data.crTransactions;
        const drTransactions = data.drTransactions;

        // Extracting labels and values from crTransactions and drTransactions
        const labels = crTransactions.map(transaction => transaction.date);
        const crValues = crTransactions.map(transaction => transaction.totalAmount);
        const drValues = drTransactions.map(transaction => transaction.totalAmount);

        if (chart) {
          chart.destroy(); // Destroy previous chart instance
        }

        setChart(new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [{
              label: 'Credit',
              data: crValues,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1
            }, {
              label: 'Debit',
              data: drValues,
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value) {
                    return '₦' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return '₦' + context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                }
              }
            }
          }
        }));
      }
    }
  }, [data]);

  return <canvas id="transactionChart" style={{ height: '300px', width: '100%' }}/>;
};

export default TransactionChart;
