import React from "react";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import Header from "./Layouts/Header";
// import { FaBars } from "react-icons/fa";
// import { UserSettings } from "./pages/SettingsUser";
import { IndexDashboard } from "./Layouts/dasboard-home/IndexDashboard";
import { TransactionPage } from "./Layouts/dasboard-home/TransactionPage";
import { BuyAirtimePage } from "./Layouts/dasboard-home/BuyAirtimePage";
import { IndexSidebar } from "./Layouts/Sidebars/Index";
import { BuyDataPage } from "./Layouts/dasboard-home/BuyDataPage";
 

export default function Dashboard() {

    // const navigate = useNavigate()
    const [sidebarReduce,] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div>
                <div className="gridContainer">
                 <Header  />
                <div className="" style={{ width: sidebarReduce ? "100px" : "200px" }}>
                    <IndexSidebar />
                </div>
                <div className="fixed top-0 hidden lg:block sub-menu z-50">
                    {/* <FaBars  className="text-2xl lg:ml-4 cursor-pointer" onClick={() => setSidebarReduce(!sidebarReduce)} /> */}
                </div>
                <div
                    style={{
                        width: sidebarReduce ? "calc(100% - 45px)" : "",
                        zIndex: sidebarReduce ? "45" : ""
                    }}
                    className="lg:main-p pt-21 home-bg pb-10 relative">
                    <Routes>
                       <Route path="/" element={<IndexDashboard/>} />
                        <Route path="/transaction" element={<TransactionPage/>} />
                        <Route path="/buyairtime" element={<BuyAirtimePage/>} />
                        <Route path="/buydata" element={<BuyDataPage/>} />
                       {/*  <Route path="freight" element={<Freight />} />
                        <Route path="inter-state" element={<InterState />} />
                        <Route path="express" element={<Express />} />
                        <Route path="procurement" element={<Procurement />} />
                        <Route path="warehouse" element={<Warehouse />} />
                        <Route path="myorders" element={<OrderUser />} />
                        <Route path="pickup-order" element={<PickUpUser />} />
                        <Route path="interstate-order" element={<InterStateOrderUser />} />
                        <Route path="oversea-order" element={<OverseaOrderUser />} />
                        <Route path="procure-order" element={<ProcureOrderUser />} />
                        <Route path="express-order" element={<ExpressOrderUser />} />
                        <Route path="warehouse-order" element={<WarehouseOrderUser />} />
                        <Route path="orderdetail" element={<OrderDetailUser />} />
                        <Route path="procuredetail" element={<ProcureDetailUser />} />
                        <Route path="warehousedetail" element={<WarehouseDetailUser/>} />
                        <Route path="transact" element={<TransactionUser />} />
                        <Route path="track" element={<Tracking />} /> */}
                        {/* admin routes */}
                        {/* <Route path="admin-pickup" element={<PickupOrders />} />
                        <Route path="admin-interstate" element={<InterStateOrder />} />
                        <Route path="admin-freight" element={<OverseaOrder />} />
                        <Route path="admin-procurement" element={<ProcureOrderAdmin />} />
                        <Route path="admin-express" element={<ExpressOrder />} />
                        <Route path="admin-warehouse" element={<WarehouseOrder />} />
                        <Route path="driver-request" element={<ViewRequest />} />
                        <Route path="customers" element={<Customers />} />
                        <Route path="partners" element={<Partners />} />
                        <Route path="sub-admin" element={<Subadmin />} />
                        <Route path="settings" element={<UserSettings />} /> */}
                        {/* Rider routes */}
                        {/* <Route path="rider-form" element={<RiderInfo />} />
                        <Route path="new-orders" element={<NewOrder />} />
                        <Route path="my-orders" element={<MyOrder />} /> */}
                    </Routes>

                </div>
                {/* <div
                    style={{
                        width: sidebarReduce ? "calc(100% - 45px)" : "",
                        zIndex: sidebarReduce ? "45" : ""
                    }}
                    className="lg:flex relative bg-white text-center lg:main-p px-5 py-5 text-primary fw-500 justify-between fs-400">
                    <div className="flex mb-5 lg:mb-0">
                        <ul className="flex w-full justify-evenly lg:justify-start">
                            <li onClick={() => navigate("/")} className="cursor-pointer">Homepage</li>
                            <li className="lg:px-6 px-3 cursor-pointer" onClick={() => navigate("/about")} >About</li>
                            <li onClick={() => navigate("/services")} className="cursor-pointer">Services</li>
                        </ul>
                    </div>
                    <div>
                        Copyright &copy; {new Date().getFullYear()}{' '} 5M Logistics
                    </div>
                </div> */}
            </div>
        </div>

    )
}