// import { Breadcrumbs } from '@material-tailwind/react'
// import React from 'react'
// import React, { useState } from 'react';
import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import Spinner from '../Spinner';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import ScuccessModal from '../../../success';
import PinModal from '../../../pincheck';


export const ElectricityPage = () => {
    const inputRef = useRef(null);
    const navigate = useNavigate()

    let business = JSON.parse(localStorage.getItem("business"));
    setInterval(() => {
        business = JSON.parse(localStorage.getItem("business"));
    }, 1000);
    const [loading, setLoading] = useState(false);
    const stopLoading = () => setLoading(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedMeterTypeItem, seTselectedMeterTypeItem] = useState(null);
    const [response, setResponse] = useState(null);
    const [vbutton, setvButton] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenSuccess, setIsModalSuccess] = useState(false);

    const handleItemClick = async (item) => {
        // setLoading(true)
        setSelectedItem(item);
        // setSelectedItem(item === selectedItem ? null : item);
        // Fetch data based on the selected item using Axios
        // try {
        //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/bills/datalookup`,{ network: item });
        //     console.log(response.data.data);
        //     setItemData(response.data.data);
        //     stopLoading();
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };
    const handleMeterTypeClick = async (item) => {
        // setLoading(true)
        seTselectedMeterTypeItem(item);
        // setSelectedItem(item === selectedItem ? null : item);
        // Fetch data based on the selected item using Axios
        // try {
        //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/bills/datalookup`,{ network: item });
        //     console.log(response.data.data);
        //     setItemData(response.data.data);
        //     stopLoading();
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };
    
    const [selectedAmount, setSelectedAmount] = useState(null);

    
    const handleAmountClick = (value) => {
        if (value < 0) return; // Prevent negative values
        setSelectedAmount(value);
        const formattedAmount = `₦${parseFloat(value).toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
        document.getElementById('amount').value = formattedAmount;
    };
   
    const handleAmountChange = (event) => {
        let inputValue = event.target.value;
        
        // Disallow first zero input
        if (inputValue.startsWith('0') && inputValue.length > 1) {
            inputValue = inputValue.slice(1);
        }
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setSelectedAmount(inputValue);
        }
    };


    const formatCurrency = (value) => {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) return '';
        return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    };

    // const handleAmountChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') { // Allow only numeric input or empty string
    //         setSelectedAmount(formatCurrency(inputValue)); // Format the input value
    //     }
    // };

    // const formatCurrency = (value) => {
    //     const floatValue = parseFloat(value);
    //     if (isNaN(floatValue)) return ''; // If value is not a valid number, return empty string
    //     return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    // };
    const [meterNumber, setMeterNumber] = useState('');
    // const validateMeter = () => {
    // //   const { value } = event.target;
    //   setMeterNumber();
    //   validateMeterNumber(value);
    // };
      const validateMeterNumber = async () => {
            // console.log('Validating meter number:', meterNumber);
            setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/metter/validation`,{
                meter_number: meterNumber,
                meter_type: selectedMeterTypeItem,
                amount: selectedAmount,
                service: selectedItem ,
                });
            // console.log(response.data.data);
            setResponse(response.data.data);
            stopLoading();
            setvButton(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const openModal = () => {
        setIsModalOpen(true);
      };
      
    const handlePinValidate = (isValid) => {
        if (isValid) {
        //   closeModal()
        //   console.log('PIN is correct');
          toast.success('Correct PIN');
        //   transferMopal();
          // Perform actions for correct PIN
        } else {
          toast.error('PIN is incorrect');
          // console.log('PIN is incorrect');
          // Perform actions for incorrect PIN
        }
      }; 
      const handleSucees = (isValid) => {
        if (isValid) {
          // closeModal()
          // console.log('PIN is correct');
          // toast.success('Correct PIN');
          // transferMopal();
          // Perform actions for correct PIN
        } else {
        //   toast.error('PIN is incorrect');
          // console.log('PIN is incorrect');
          // Perform actions for incorrect PIN
        }
      };
      const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));
    
        if (pastedData < 0) {
            e.preventDefault();
        }
    };
    const closeModalSuccess = () => {
        navigate('/transactions')
        setIsModalSuccess(false);
    };
    const openModalSuccess = () => {
        setIsModalSuccess(true);
    };
    const closeModal = () => {
        
      setIsModalOpen(false);
    };
 

    useEffect(() => {
        const token = localStorage.getItem('lpin');
        axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
        // document.addEventListener('click', handleClickOutside);
        // return () => {
        //   document.removeEventListener('click', handleClickOutside);
        // };
    }, []);

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            // console.log(meterNumber);
          validateMeterNumber(meterNumber);
        }
      };

      const handleInputChange = (event) => {
        const { value } = event.target;
        // console.log(value);
        setMeterNumber(value);
      };

  return (
   
     <section className="mainBodyContainer">
            <div className="container-fluid dashboardSummary">
              <div className="row">
                    <div className="col-sm-12">
                        <div className="materialCard materialTableContainer mt-5">
 
                            <div className="flex-between" style={{ }}>
                              
                            <div className="materialCardForm" style={{width: '80%'}}>
                                    <div className="">
                                        <div className="px-5 ml-5">
                                       <div class="card-body">
                            {/* <form action="https://app.payscribe.ng/dashboard/electricity" role="form" id="electricity-form" method="post" accept-charset="utf-8" data-gtm-form-interact-id="0"> */}
							                                <div class="form-group">
                                    <label for="network">Select disco provider</label>
									<div class="scrolling-wrapper">
                                            <div title="Ikeja Electric" className={`network-item border-1 ikedc ${selectedItem === 'ikedc' ? 'selected' : ''}`} onClick={() => handleItemClick('ikedc')} data-percent="0.5" data-network="ikedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/y46nwahaomun7cicmrcj.png" data-value="8" data-message="iedc::42 | customerAccountNumber_3281,iedc postpaid::41 | customerAccountNumber_2573">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/y46nwahaomun7cicmrcj.png" alt="Ikeja Electric"/>
											</span>
												<span class="network-name mr-2">IKEDC</span>
												<span></span>
											</div>
                                            <div title="Eko Electricity" className={`network-item border-1 ekedc ${selectedItem === 'ekedc' ? 'selected' : ''}`} onClick={() => handleItemClick('ekedc')} data-percent="0.5" data-network="ekedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/jlvk8ldmjglmlufk4ofl.png" data-value="9" data-message="EKEDC_PREPAID::1171| customerAccountNumber_3795, EKEDC_POSTPAID::1170 | customerAccountNumber_3785">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/jlvk8ldmjglmlufk4ofl.png" alt="Eko Electricity"/>
											</span>
												<span class="network-name mr-2">EKEDC</span>
												<span></span>
											</div>
                                            <div title="Enugu Electricity" className={`network-item border-1 eedc ${selectedItem === 'eedc' ? 'selected' : ''}`} onClick={() => handleItemClick('eedc')} data-percent="0.6" data-network="eedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/zvzbavxqf7tuxykgqamy.png" data-value="10" data-message="EEDC::1182| customerAccountNumber_4066,EEDC::1182| customerAccountNumber_4066">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/zvzbavxqf7tuxykgqamy.png" alt="Enugu Electricity"/>
											</span>
												<span class="network-name mr-2">EEDC</span>
												<span></span>
											</div>
                                            <div title="Ibadan Electricity" className={`network-item border-1 ibedc ${selectedItem === 'ibedc' ? 'selected' : ''}`} onClick={() => handleItemClick('ibedc')} data-percent="0.3" data-network="ibedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/d2ytyrnmdsispvwa1j6z.png" data-value="11" data-message="IBEDC_F::1169| customerAccountNumber_3755 , IBEDC_F::1169| customerAccountNumber_3755">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/d2ytyrnmdsispvwa1j6z.png" alt="Ibadan Electricity"/>
											</span>
												<span class="network-name mr-2">IBEDC</span>
												<span></span>
											</div>
                                            <div title="Portharcourt Electricity" className={`network-item border-1 phedc ${selectedItem === 'phedc' ? 'selected' : ''}`} onClick={() => handleItemClick('phedc')} data-percent="0.8" data-network="phedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/nup6tnxdifg21gbasfbf.jpg" data-value="19" data-message="PHEDDIR2::1116| customerAccountNumber_2681, PHEDDIR2::1116| customerAccountNumber_2681">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/nup6tnxdifg21gbasfbf.jpg" alt="Portharcourt Electricity"/>
											</span>
												<span class="network-name mr-2">PHEDC</span>
												<span></span>
											</div>
                                            <div title="Abuja Electricity" className={`network-item border-1 aedc ${selectedItem === 'aedc' ? 'selected' : ''}`} onClick={() => handleItemClick('aedc')} data-percent="0.7" data-network="aedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/abuja-electricity.jpg" data-value="21" data-message="ABJ_PREPAID::1106 | customerAccountNumber_2493 , ABJ_POSTPAID::1104 | customerAccountNumber_2487">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/abuja-electricity.jpg" alt="Abuja Electricity"/>
											</span>
												<span class="network-name mr-2">AEDC</span>
												<span></span>
											</div>
                                            <div title="Kano Electricity (KEDCO)" className={`network-item border-1 kadco ${selectedItem === 'kadco' ? 'selected' : ''}`} onClick={() => handleItemClick('kadco')} data-percent="0.5" data-network="kadco" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/esfp12oso626epebus9a.png" data-value="28" data-message="KEDCO_PREPAID::1108| customerAccountNumber_2615,KEDCO_POSTPAID::1107| customerAccountNumber_2608">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/esfp12oso626epebus9a.png" alt="Kano Electricity (KEDCO)"/>
											</span>
												<span class="network-name mr-2">KADCO</span>
												<span></span>
                                              </div>
                                            <div title="JOS Disco (JED)" className={`network-item border-1 jed ${selectedItem === 'jed' ? 'selected' : ''}`} onClick={() => handleItemClick('jed')} data-percent="0.5" data-network="jed" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/xsychvwiykssvm99c6fy.png" data-value="31" data-message="JOS_PREPAID::1114 | customerAccountNumber_field3,JOS_POSTPAID::1111| customerAccountNumber_field2">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/xsychvwiykssvm99c6fy.png" alt="JOS Disco (JED)"/>
											</span>
												<span class="network-name mr-2">JED</span>
												<span></span>
											</div>
                                            <div title="BEDC Disco" className={`network-item border-1 bedc ${selectedItem === 'bedc' ? 'selected' : ''}`} onClick={() => handleItemClick('bedc')} data-percent="0.5" data-network="bedc" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/r0gatdespt03dqbsr9ak.jpg" data-value="53" data-message="bedc">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/r0gatdespt03dqbsr9ak.jpg" alt="BEDC Disco"/>
											</span>
												<span class="network-name mr-2">BEDC</span>
												<span></span>
											</div>
                                            <div title="Kaduna Electricity" className={`network-item border-1 Kaedco ${selectedItem === 'Kaedco' ? 'selected' : ''}`} onClick={() => handleItemClick('Kaedco')} data-percent="0.3" data-network="Kaedco" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/esfp12oso626epebus9a.png" data-value="57" data-message="KAEDCO_PREPAID::1108| customerAccountNumber_2615,KAEDCO_POSTPAID::1107| customerAccountNumber_2608">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/esfp12oso626epebus9a.png" alt="Kaduna Electricity"/>
											</span>
												<span class="network-name mr-2">KAEDCO</span>
												<span></span>
											</div>
                                                <div title="Aba Electricity" className={`network-item border-1 aba ${selectedItem === 'aba' ? 'selected' : ''}`} onClick={() => handleItemClick('aba')} data-percent="0.3" data-network="aba" data-icon-url="https://res.cloudinary.com/philo001/image/upload/payscribe/" data-value="58" data-message="ABA_PREPAID::1108| customerAccountNumber_2615,ABA_POSTPAID::1107| customerAccountNumber_2608">
											<span class="mr-2">
												<img class="network-img" src="https://res.cloudinary.com/philo001/image/upload/payscribe/" alt="Aba Electricity"/>
											</span>
												<span class="network-name mr-2">ABA</span>
												<span></span>
											</div>
																			</div>
                                </div>
															<div class="form-group">
								<label for="meter_type">Select meter type</label>
								<div class="d-flex justify-content-between">
									<div className={`meter-type flex-grow-1 border-1 prepaid ${selectedMeterTypeItem === 'prepaid' ? 'selectedMeterType' : ''}`} onClick={() => handleMeterTypeClick('prepaid')} data-type="prepaid">
										<span>Prepaid Meter</span>
									</div>
									<div className={`meter-type flex-grow-1 border-1 postpaid ${selectedMeterTypeItem === 'postpaid' ? 'selectedMeterType' : ''}`} onClick={() => handleMeterTypeClick('postpaid')} data-type="postpaid">
										<span>Postpaid Meter</span>
									</div>
								</div>
							</div>

                                <div class="form-group">
                                    <label>Select meter number</label>
                                    <input class="form-control" type="text" name="number" required="" placeholder="Enter Meter Number" onChange={handleInputChange}  ref={inputRef} />
                                </div>

								<div class="form-group">
									<label>Amount</label>
									<div class="scrolling-wrapper my-2">
										<div className={`amount-item justify-content-center border-1 ${selectedAmount === '1000' ? 'selectedAmount' : ''}`} data-amt="1000" onClick={() => handleAmountClick('1000')} >
											<span class="network-name">₦1,000</span>
										</div>
										<div className={`amount-item justify-content-center border-1 ${selectedAmount === '2000' ? 'selectedAmount' : ''}`} data-amt="2000" onClick={() => handleAmountClick('2000')} >
											<span class="network-name">₦2,000</span>
										</div>
										<div className={`amount-item justify-content-center border-1 ${selectedAmount === '5000' ? 'selectedAmount' : ''}`} data-amt="5000" onClick={() => handleAmountClick('5000')}>
											<span class="network-name">₦5,000</span>
										</div>
										<div className={`amount-item justify-content-center border-1 ${selectedAmount === '10000' ? 'selectedAmount' : ''}`} data-amt="10000" onClick={() => handleAmountClick('10000')} >
											<span class="network-name">₦10,000</span>
										</div>
										<div className={`amount-item justify-content-center border-1 ${selectedAmount === '50000' ? 'selectedAmount' : ''}`} data-amt="50000" onClick={() => handleAmountClick('50000')}>
											<span class="network-name">₦50,000</span>
										</div>
									</div>
									{/* <input class="form-control number" type="text" name="amount" id="amount" min="100" placeholder="Or Enter The Amount" value=""/> */}
                                    {/* <input
                                        className="form-control"type="text"
                                        name="amount"
                                        id="amount"
                                        value={selectedAmount}
                                        onChange={handleAmountChange}
                                        placeholder="Enter The Amount"
                                    /> */}
                                     <input
                                            className="form-control"
                                            type="text"
                                            name="amount"
                                            id="amount"
                                            value={selectedAmount}
                                            onChange={handleAmountChange}
                                            placeholder="Enter The Amount"
                                            onPaste={preventPasteNegative}
                                            onKeyPress={preventMinus}
                                        />
								</div>
                                {loading ? <Spinner /> : (
                                    response && (
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-12">
                                                <label for="name"> Name</label>
                                                <input class="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerName" id="customerName" value={response.customer_name}/>
                                            </div>

                                            <div class="form-group col-md-6 col-12">
                                                <label for="name"> Address</label>
                                                <input class="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerInfo" id="customerInfo" value={response.address}/>
                                                <input class="form-control" type="hidden" readOnly name="product_code" id="product_code" value={response.productCode} />
                                            </div>
                                        </div>
                                    )
                                )}
                                <div class="form-group">
									<label for="name">Phone Number</label>
									<input class="form-control" required="" type="number" id="phone" name="phone_number"/>
									{/* <span class="text-danger text-sm">Customer valid phone number required</span> */}
								</div>

								{/* <button type="button" onclick="previewTransaction('electricity-form')" data-toggle="modal" data-wallet="0.00|0.00" data-target="#openPreview" data-backdrop="static" data-keyboard="false" class="btn btn-sm btn-primary ctn col-sm-12">
									PROCEED
								</button> */}
                                {vbutton ?
                                // <input type="button" onClick={validateMeterNumber} className="btn-primary ctn col-sm-12" value={'PROCEED'}/>
                                <div>
                                     <input type="button" onClick={openModal} className="btn-primary ctn col-sm-12" value={'PROCEED'}/> 
                                    <PinModal isOpen={isModalOpen} onClose={closeModal} onPinValidate={handlePinValidate} />
                                    <ScuccessModal isOpen={isModalOpenSuccess} onClose={closeModalSuccess} onPinValidate={handleSucees} />
                                </div>
                                :
                                <input type="button" onClick={validateMeterNumber} className="btn-primary ctn col-sm-12" value={'VALIDATE'}/>
                            }
								{/* </form>							 */}
                                </div>
                                   
                                                    {/* <select style={{ border: 'none', background: '#f3f5f7' }}>
                                                        <option value="" selected disabled>Transactions</option>
                                                        <option value="">All</option>
                                                        <option value="Withdrawal">Withdrawal</option>
                                                        <option value="Transfer">Transfer</option>
                                                        <option value="Bills">Bills</option>
                                                    </select> */}
                                    </div>
                                                {/* <div>
                                                    <select style={{border: 'none', background: '#f3f5f7'}}>
                                                        <option value="" selected disabled>Status</option>
                                                        <option value="">All</option>
                                                        <option value="Success">Success</option>
                                                        <option value="Failed">Failed</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                        </div>
                            </div>

                            {/* <div className="table-responsive">
                                <table className="table table-borderless dataTable">
                                    <thead>
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Fees</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">TCID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Success</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
          
  )
}
