import React, { useState, useEffect, useRef } from 'react';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import Spinner from '../Spinner';
import { decryptData } from '../../../../utils/util';

export const CowryPage = () => {
    let user = null;

// Check if localStorage item exists and decrypt it to obtain 'user' data
const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
if (mkLocalData) {
    const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
    const originalData = decryptData(mkLocalData, salt);
    user = JSON.parse(originalData);
}
    // let user = JSON.parse(localStorage.getItem("user"));
    // setInterval(() => {
    //     business = JSON.parse(localStorage.getItem("business"));
    // }, 1000);
    
    // function NetworkItems() {

    // }
    const [selectedItems, setSelectedItems] = useState([]);
    

    const [selectedItem, setSelectedItem] = useState(null);
    const [vbutton, setvButton] = useState(false);
    const [response, setResponse] = useState(null);
    const [phone_number, setPhonenumber] = useState(null);
    const stopLoading = () => setLoading(false);
    const [loading, setLoading] = useState(false);


    const handleItemClick = (value) => {
        setSelectedItem(value === selectedItem ? null : value);
        console.log(`${value}`);
    };
    const [selectedAmount, setSelectedAmount] = useState(null);
   

    
    const handleAmountClick = (value) => {
        if (value < 0) return; // Prevent negative values
        setSelectedAmount(value);
        const formattedAmount = `₦${parseFloat(value).toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
        document.getElementById('amount').value = formattedAmount;
    };
   
    const handleAmountChange = (event) => {
        let inputValue = event.target.value;
        
        // Disallow first zero input
        if (inputValue.startsWith('0') && inputValue.length > 1) {
            inputValue = inputValue.slice(1);
        }
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setSelectedAmount();
        }
    };
     const handlePhoneChange = (event) => {
        let inputValue = event.target.value;
        // Disallow first zero input
        // if (inputValue.startsWith('0') && inputValue.length > 1) {
        //     inputValue = inputValue.slice(1);
        // }
        // if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
        //     setSelectedAmount();
        // }
        setPhonenumber(inputValue);
    };


    const formatCurrency = (value) => {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) return '';
        return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    };

    // const handleAmountChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') { // Allow only numeric input or empty string
    //         setSelectedAmount(formatCurrency(inputValue)); // Format the input value
    //     }
    // };

    // const formatCurrency = (value) => {
    //     const floatValue = parseFloat(value);
    //     if (isNaN(floatValue)) return ''; // If value is not a valid number, return empty string
    //     return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    // };
    const validateMeterNumber = async () => {
        // console.log('Validating meter number:', meterNumber);
        setLoading(true)
    try {
        console.log(user.id)
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/validatevehicle/${user.id}/${phone_number}`
        // ,{
            // meter_number: phone_number,
            // meter_type: selectedMeterTypeItem,
            // amount: selectedAmount,
            // service: selectedItem ,
            // }
        );
        console.log(response.data.data.data);
        setResponse(response.data.data.data);
        stopLoading();
        setvButton(true)
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

useEffect(() => {
    const token = localStorage.getItem('lpin');
    axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
    // document.addEventListener('click', handleClickOutside);
    // return () => {
    //   document.removeEventListener('click', handleClickOutside);
    // };
}, []);
  return (
   
     <section className="mainBodyContainer">
            <div className="container-fluid dashboardSummary">
              <div className="row">
                    <div className="col-sm-12">
                        <div className="materialCard materialTableContainer mt-5">
 
                            <div className="flex-between" style={{ }}>
                              
                            <div className="materialCardForm" style={{width: '80%'}}>
                                    <div className="">
                                        <div className="px-5 ml-5">
                                        <div className="card-body">
                                            
                                        {/* <form onSubmit={}> */}
							                <div className="form-group">
                                            {/* <label for="network">Select network provider</label> */}
                                           <div className="scrolling-wrapper">
                                           {/* <div className={`network-item border-1 9mobile ${selectedItem === '9mobile' ? 'selected' : ''}`} onClick={() => handleItemClick('9mobile')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={mobile} alt="9mobile Airtime" />
                                                </span>
                                                <span className="network-name">9MOBILE</span>
                                                <span></span>
                                                {selectedItem === '9mobile' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div> 
                                             <div className={`network-item border-1 glo ${selectedItem === 'glo' ? 'selected' : ''}`} onClick={() => handleItemClick('glo')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={glo}  alt="glo Airtime" />
                                                </span>
                                                <span className="network-name">Glo</span>
                                                <span></span>
                                                {selectedItem === 'glo' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div>
                                             <div className={`network-item border-1 airtel ${selectedItem === 'airtel' ? 'selected' : ''}`} onClick={() => handleItemClick('airtel')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={airtel} alt="airtel Airtime" />
                                                </span>
                                                <span className="network-name">Airtel</span>
                                                <span></span>
                                                {selectedItem === 'airtel' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div>
                                             <div className={`network-item border-1 mtn ${selectedItem === 'mtn' ? 'selected' : ''}`} onClick={() => handleItemClick('mtn')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={mtn}  alt="mtn Airtime" />
                                                </span>
                                                <span className="network-name">Mtn</span>
                                                <span></span>
                                                {selectedItem === 'mtn' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div> */}
                                            
																	</div>
							</div>
							<div className="form-group">
								<label>Phone number</label>
                                <input 
                                        placeholder="Enter Phone Number" 
                                        name="phone" 
                                        id="phone" 
                                        className="form-control" 
                                        maxLength={11} 
                                        onChange={handlePhoneChange}
                                    />
								{/* <div className="d-flex justify-content-between align-items-center">
									<span>
										<a href="#" className="multipleNetwork text-primary text-sm" data-product="2" data-toggle="modal" data-target="#multipleNetwork">
											Don't know the networks?
										</a>
									</span>
									<span><a href="#" className="uploadMultiple text-primary text-sm" data-product="2" data-toggle="modal" data-target="#uploadMultiple">Upload CSV</a>
									</span>
								</div> */}
							</div>
							<div className="form-group m-scroll" style={{}}>
								<table className="table table-striped table-bordered" id="contact-list">
									<tbody className="contact-body">
									</tbody>
								</table>
							</div>
							{/* <div className="save-contact-container" style={{paddingBottom: 15, }}>
								<span className="text-dark" id="save-confirmation" style={{}}>Hi Momentum Developer, Will you like to save this phone number as a beneficiary <a className="save-anchor" onclick="saveContact(1)"><b className="text-primary">Yes</b></a> | <a className="save-anchor" onclick="saveContact(0)">No</a></span>
								<div style={{}} id="save-contact-box">
									<div className="form-row ">
										<div className="col-9">
											<input type="text" className="form-control" id="contact-name" placeholder="Enter Contact Name"/>
										</div>
										<div className="col-3 no-gutters no-padding ">
											<button type="button" className="btn btn-success save-contact btn-sm mr-2"><i className="fa fa-save"></i> </button>
											<button type="button" className="btn btn-secondary" onclick="$('#save-contact-box').fadeOut();">
												<i className="fa fa-times"></i>
											</button>
										</div>
									</div>
								</div>
							</div> */}

                            <div className="form-group">
                                        <label>Amount</label>
                                        <div className="scrolling-wrapper my-2">
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '100' ? 'selected' : ''}`} data-amt="100" onClick={() => handleAmountClick('100')}>
                                                <span className="network-name">₦100</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '200' ? 'selected' : ''}`} data-amt="200" onClick={() => handleAmountClick('200')}>
                                                <span className="network-name">₦200</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '500' ? 'selected' : ''}`} data-amt="500" onClick={() => handleAmountClick('500')}>
                                                <span className="network-name">₦500</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '1000' ? 'selected' : ''}`} data-amt="1000" onClick={() => handleAmountClick('1000')}>
                                                <span className="network-name">₦1,000</span>
                                            </div>
                                            <div className={`amount-item justify-content-center border-1 ${selectedAmount === '5000' ? 'selected' : ''}`} data-amt="5000" onClick={() => handleAmountClick('5000')}>
                                                <span className="network-name">₦5,000</span>
                                            </div>
                                        </div>
                                        {/* <input className="form-control" type="text" name="amount" id="amount" min="0" placeholder="Enter The Amount" /> */}
                                        <input
                className="form-control"
                type="text"
                name="amount"
                id="amount"
                value={selectedAmount}
                onChange={handleAmountChange}
                placeholder="Enter The Amount"
            />
                                    </div>

						 

							<input type="hidden" name="network" id="network" value="9mobile"/>
							<input type="hidden" name="postType" value="single"/>
							<input type="hidden" name="discount" id="discount" value="3"/>
							<input type="hidden" name="network_id" id="network_id" value="23"/>
							{/* <div className="d-flex justify-content-between my-2">
								<a title="Upgrade limit" data-toggle="modal" data-target="#viewLimit" href="#">
									View Limit
								</a>
							</div> */}
							{/* <input type="button" onclick="previewTransaction('airtime')" data-wallet="8499.00|0.00" data-toggle="modal" data-target="#openPreview" data-backdrop="static" data-keyboard="false" className="btn btn-sm btn-primary ctn col-sm-12" value={'PROCEED'}/> */}
                            {loading ? <Spinner /> : (
                                    response && (
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-12">
                                                <label for="name"> Name</label>
                                                <input class="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerName" id="customerName" value={response.fullname}/>
                                            </div>

                                            <div class="form-group col-md-6 col-12">
                                                <label for="name"> Address</label>
                                                <input class="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerInfo" id="customerInfo" value={response.phone}/>
                                                <input class="form-control" type="hidden" readOnly name="product_code" id="product_code" value={response.productCode} />
                                            </div>
                                        </div>
                                    )
                                )}

                            {vbutton ?
                                <input type="button" onClick={validateMeterNumber} className="btn-primary ctn col-sm-12" value={'PROCEED'}/>
                                :
                                <input type="button" onClick={validateMeterNumber} className="btn-primary ctn col-sm-12" value={'VALIDATE'}/>
                            }
 							{/* </form>						 */}
                            </div>
                            
                                            {/* <select style={{ border: 'none', background: '#f3f5f7' }}>
                                                <option value="" selected disabled>Transactions</option>
                                                <option value="">All</option>
                                                <option value="Withdrawal">Withdrawal</option>
                                                <option value="Transfer">Transfer</option>
                                                <option value="Bills">Bills</option>
                                            </select> */}
                                        </div>
                                        {/* <div>
                                            <select style={{border: 'none', background: '#f3f5f7'}}>
                                                <option value="" selected disabled>Status</option>
                                                <option value="">All</option>
                                                <option value="Success">Success</option>
                                                <option value="Failed">Failed</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="table-responsive">
                                <table className="table table-borderless dataTable">
                                    <thead>
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Fees</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">TCID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Success</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
          
  )
}
