import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./messages";

import AuthService from "../../services/auth.service";
import { decryptData } from "../../utils/util";

// const user = JSON.parse(localStorage.getItem("user")); 
let user = null;

// Check if localStorage item exists and decrypt it to obtain 'user' data
const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
if (mkLocalData) {
    const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
    const originalData = decryptData(mkLocalData, salt);
    user = JSON.parse(originalData);
}

export const register = createAsyncThunk("/auth/signup", async (payload, thunkAPI) => {
  try {
    const response = await AuthService.register(payload);
    thunkAPI.dispatch(setMessage(response.data));
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const login = createAsyncThunk(
    "/auth/signin",
    async (payload, thunkAPI,) => {
        // console.log('sddedewd')
        // console.log(payload[0])
        // console.log(stopLoading())
        try {
            const response = await AuthService.login(payload);
            // stopLoading();
            // console.log(response)
            // console.log(`redd ${response}`)
            // console.log('response====')
            thunkAPI.dispatch(setMessage(response));
            return response;
        } catch (error) {
            // stopLoading();
            // console.log('error')
            // console.log(error.response.data.message)
            const message =error.response.data.message.toString();
                // (error.response &&
                // error.response.data &&
                // error.response.data.message) ||
                // error.message ||
                // error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);
// export const loginUser = (apiData, navigate, stopLoading) => {
//     return async (dispatch) => {
//         try {
//             const url = `/user/login`;
//             const response = await axios.post(url, apiData);
            
//             dispatch(login(response));
//             stopLoading();
//             // Swal.fire({
//             //     title: "Success",
//             //     imageUrl: "https://t4.ftcdn.net/jpg/05/10/52/31/360_F_510523138_0c1lsboUsa9qvOSxdaOrQIYm2eAhjiGw.jpg",
//             //     imageWidth: "75px",
//             //     text: "Login completed successfully",
//             //     buttonsStyling: "false",
//             //     confirmButtonText: "Continue",
//             //     confirmButtonColor: "#3F79AD",
//             // }).then(() => {
//             //     navigate("/dashboard");
//             // })
//             navigate("/dashboard");
//         } catch (error) {
//             console.log(error.message);
//             const errors = error.response.data.message;
//             stopLoading();
//             dispatch(setError(errors));
//             dispatch(setAlert(errors, "danger"))
//             toaster.notify(
//                 errors,
//                 {
//                     duration: "4000",
//                     position: "top",
//                 }
//             );
//         }
//     }
// }

export const adminLogin = createAsyncThunk(
    "/admin/login",
    async (payload, thunkAPI) => {
        try {
            const response = await AuthService.adminLogin(payload);
            thunkAPI.dispatch(setMessage(response));
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const verifyAccount = createAsyncThunk(
    "/auth/email/confirm",
    async (payload, thunkAPI) => {
        try {
            const response = await AuthService.verifyAccount(payload);
            thunkAPI.dispatch(setMessage(response));
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const resendVerification = createAsyncThunk(
    `/auth/email/verify/resend/:id`,
    async (email, thunkAPI) => {
        try {
            const response = await AuthService.resendVerification(email);
            thunkAPI.dispatch(setMessage(response));
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const forgetPassword = createAsyncThunk(
    `/auth/password/email`,
    async (payload, thunkAPI) => {
        try {
            const response = await AuthService.forgetPassword(payload);
            thunkAPI.dispatch(setMessage(response));
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const resetPassword = createAsyncThunk(
    `/auth/password/reset`,
    async (payload, thunkAPI) => {
        try {
            const response = await AuthService.resetPassword(payload);
            thunkAPI.dispatch(setMessage(response.data));
            return response.data;
        } catch (error) {
            const message =
                    error.response.data
            console.log(message);
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const logout = createAsyncThunk("/auth/logout", async () => {
    await AuthService.logout();
    window.location.href = "/login";
});

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [adminLogin.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload
        },
        [adminLogin.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null
        }
    },
});

const { reducer } = authSlice;
export default reducer;