import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import Spinner from '../Spinner';
import axios from 'axios';
import gotv from '../../../../images/bill/gotv.png';
import dstv from '../../../../images/bill/dstv.png';
import startimes from '../../../../images/bill/startime.png';
import dstvshowmax from '../../../../images/bill/showmax.png';
import { Select } from 'react-select';

export const CableTvPage = () => {
    let business = JSON.parse(localStorage.getItem("business"));
    setInterval(() => {
        business = JSON.parse(localStorage.getItem("business"));
    }, 1000);
    const [loading, setLoading] = useState(false);
    const [loadingg, setLoadingg] = useState(false);
    const [vbutton, setvButton] = useState(false);
    

    const stopLoading = () => setLoading(false);
    const stopLoadingg = () => setLoadingg(false);

    const [selectedItems, setSelectedItems] = useState([]);
    
    // const handleItemClick = (value) => {
    //     if (selectedItems.includes(value)) {
    //         setSelectedItems(selectedItems.filter(item => item !== value));
    //     } else {
    //         setSelectedItems([...selectedItems, value]);
    //     }
    // };
    const [selectedItem, setSelectedItem] = useState(null);
    // const [itemData, setItemData] = useState(null);
    const [itemData, setItemData] = useState([]);
    const [infoData, setIinfoData] = useState([]);
    const [smartcard, setSmartCardNumber] = useState(null);
    const [plaid, setPlanId] = useState(null);
    const [months,setMonths]= useState([]);
    const [month,setMonth]= useState([]);
    const [response, setResponse] = useState(null);

    // const handleItemClick = (value) => {
    //     setSelectedItem(value === selectedItem ? null : value);
    //     console.log(`${value}`);

    // };
    const handleItemClick = async (item) => {
        setLoading(true)
        setSelectedItem(item);
        // setSelectedItem(item === selectedItem ? null : item);
         try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/cabletv/packages/${item}`,
            // {
            //     type: item,
            //     account: smartcard
            //      }
                 );
            console.log(response.data.data);
            setItemData(response.data.data);
            stopLoading();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const verifySmartcard = async () => {
        console.log('item verified');
        console.log(selectedItem);
        console.log(smartcard);
        console.log(month);
        console.log(plaid);
        setLoadingg(true)
        // setSelectedItem(item);
        // setSelectedItem(item === selectedItem ? null : item);

         try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/cabletv/validation`,
            {
                type: selectedItem,
                account: smartcard,
                month: month,
                planid: plaid,
            });
            console.log(response.data.data);
            setResponse(response.data.data);
            stopLoadingg();
            setvButton(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getMonths = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/months`,
             
                 );
            console.log(response.data.data);
            setMonths(response.data.data);
            stopLoading();
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

    const handleSmartCard = (event) => {
        let inputValue = event.target.value;
        
        // // Disallow first zero input
        // if (inputValue.startsWith('0') && inputValue.length > 1) {
        //     inputValue = inputValue.slice(1);
        // }
        // if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
        // }
        // console.log(inputValue);
        setSmartCardNumber(inputValue);
    };

    const selectDataPlan = (event) => {
        let inputValue = event.target.value;
        // console.log(inputValue);
        setPlanId(inputValue);
    };
    
    const handleMonth = (event) => {
        let inputValue = event.target.value;
        console.log(inputValue);
        setMonth(inputValue);
    };
    
    const [selectedAmount, setSelectedAmount] = useState(null);

    
    const handleAmountClick = (value) => {
        if (value < 0) return; // Prevent negative values
        setSelectedAmount(value);
        const formattedAmount = `₦${parseFloat(value).toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
        document.getElementById('amount').value = formattedAmount;
    };
   
    const handleAmountChange = (event) => {
        let inputValue = event.target.value;
        
        // Disallow first zero input
        if (inputValue.startsWith('0') && inputValue.length > 1) {
            inputValue = inputValue.slice(1);
        }
        if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
            setSelectedAmount();
        }
    };


    const formatCurrency = (value) => {
        const floatValue = parseFloat(value);
        if (isNaN(floatValue)) return '';
        return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    };

    // const handleAmountChange = (event) => {
    //     const inputValue = event.target.value;
    //     if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') { // Allow only numeric input or empty string
    //         setSelectedAmount(formatCurrency(inputValue)); // Format the input value
    //     }
    // };

    // const formatCurrency = (value) => {
    //     const floatValue = parseFloat(value);
    //     if (isNaN(floatValue)) return ''; // If value is not a valid number, return empty string
    //     return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
    // };

    useEffect(() => {
        // Retrieve token from localStorage
        // Add the token to Axios headers
        const token = localStorage.getItem('lpin');
        axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
        getMonths();
    }, []);

   

  return (
   
     <section className="mainBodyContainer">
            <div className="container-fluid dashboardSummary">
              <div className="row">
                    <div className="col-sm-12">
                        <div className="materialCard materialTableContainer mt-5">
 
                            <div className="flex-between" style={{ }}>
                              
                            <div className="materialCardForm" style={{width: '80%'}}>
                                    <div className="">
                                        <div className="px-5 ml-5">
                                       
                                     <div className="card-body">
                                        {/* <form onSubmit={}> */}
							             <div className="form-group">
                                            <label for="network">Select network provider</label>
                                           <div className="scrolling-wrapper">
                                           <div className={`network-item border-1 gotv ${selectedItem === 'gotv' ? 'selected' : ''}`} onClick={() => handleItemClick('gotv')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={gotv} alt="gotv " />
                                                </span>
                                                <span className="network-name">GoTV</span>
                                                <span></span>
                                                {selectedItem === 'gotv' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div> 
                                             <div className={`network-item border-1 dstv ${selectedItem === 'dstv' ? 'selected' : ''}`} onClick={() => handleItemClick('dstv')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={dstv} alt="dstv" />
                                                </span>
                                                <span className="network-name">DsTV</span>
                                                <span></span>
                                                {selectedItem === 'dstv' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div>
                                             <div className={`network-item border-1 startimes ${selectedItem === 'startimes' ? 'selected' : ''}`} onClick={() => handleItemClick('startimes')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={startimes} alt="startimes" />
                                                </span>
                                                <span className="network-name">startimes</span>
                                                <span></span>
                                                {selectedItem === 'startimes' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div>
                                             <div className={`network-item border-1 dstvshowmax ${selectedItem === 'dstvshowmax' ? 'selected' : ''}`} onClick={() => handleItemClick('dstvshowmax')}>
                                                <span className="mr-2">
                                                    <img className="network-img" src={dstvshowmax} alt="dstvshowmax " />
                                                </span>
                                                <span className="network-name">dstvshowmax</span>
                                                <span></span>
                                                {selectedItem === 'dstvshowmax' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />}
                                            </div>
                                            
										</div>
							</div>
							<div className="form-group">
                            {
                                loading ? <Spinner /> : 
							<div class="form-group y-scroll">
                            <table class="table data-table table-striped table-hover table-bordered" style={{fontsize: 13}}>
                            {itemData.length > 0 ? (
                                        itemData.map((item, index) => (
                                                <tbody key={index} class="data-body"><tr>

                                                    <td align="center">
                                                    <input title="Select 40MB - 1 Day - SME for ₦50" type="radio" onChange={selectDataPlan} name="plan" value={item.id}required=""/></td>
                                                    <td><img className="network-img" src={selectedItem == 'gotv' ?gotv :selectedItem == 'dstv' ?dstv:selectedItem == 'startimes' ?startimes: dstvshowmax } /></td>
                                                    <td>{item.name}</td><td>{formatCurrency(item.amount)}</td>
                                                </tr></tbody>
                                            
                                        ))
                                    ) : (
                                        <p></p>
                                    )}
                               </table>     
				
							</div>
                              }
								<label>Enter smartcard number</label>
                                <input 
                                        placeholder="Enter smartcard number" 
                                        name="smartcard" 
                                        id="smartcard" 
                                        className="form-control" 
                                        onChange={handleSmartCard}
                                        // maxLength={11} 
                                    />
                                {/* <div class="form-group"> */}
								<label for="months">How many month?</label>
								<select class="form-control mb-2 " name="month">
									<option className='mb-4' value="" selected="">Select Month </option>
                                     {months.length > 0 ? (
                                        months.map((item, index) => (
                                            <option value={item.value} onChange={handleMonth}>{item.name}</option>
                                        ))
                                    ) : (
                                        <p></p>
                                    )}
								</select>
							{/* </div>     */}
								{/* <div className="d-flex justify-content-between align-items-center">
									<span>
										<a href="#" className="multipleNetwork text-primary text-sm" data-product="2" data-toggle="modal" data-target="#multipleNetwork">
											Don't know the networks?
										</a>
									</span>
									<span><a href="#" className="uploadMultiple text-primary text-sm" data-product="2" data-toggle="modal" data-target="#uploadMultiple">Upload CSV</a>
									</span>
								</div> */}
							</div>
							{/* <div className="form-group m-scroll" style={{}}>
								<table className="table table-striped table-bordered" id="contact-list">
									<tbody className="contact-body">
									</tbody>
								</table>
							</div> */}
							{/* <div className="save-contact-container" style={{paddingBottom: 15, }}>
								<span className="text-dark" id="save-confirmation" style={{}}>Hi Momentum Developer, Will you like to save this phone number as a beneficiary <a className="save-anchor" onclick="saveContact(1)"><b className="text-primary">Yes</b></a> | <a className="save-anchor" onclick="saveContact(0)">No</a></span>
								<div style={{}} id="save-contact-box">
									<div className="form-row ">
										<div className="col-9">
											<input type="text" className="form-control" id="contact-name" placeholder="Enter Contact Name"/>
										</div>
										<div className="col-3 no-gutters no-padding ">
											<button type="button" className="btn btn-success save-contact btn-sm mr-2"><i className="fa fa-save"></i> </button>
											<button type="button" className="btn btn-secondary" onclick="$('#save-contact-box').fadeOut();">
												<i className="fa fa-times"></i>
											</button>
										</div>
									</div>
								</div>
							</div> */}
 

						 
                        {loadingg ? <Spinner /> : (
                                    response && (
                                        <div class="form-row">
                                            <div class="form-group col-md-6 col-12">
                                                <label for="name"> Name</label>
                                                <input class="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerName" id="customerName" value={response.customer_name}/>
                                            </div>

                                            <div class="form-group col-md-6 col-12">
                                                <label for="name"> Address</label>
                                                <input class="form-control" type="text" placeholder="This will be auto-filled" readOnly name="customerInfo" id="customerInfo" value={response.account}/>
                                                <input class="form-control" type="hidden" readOnly name="product_code" id="product_code" value={response.plan_id} />
                                            </div>
                                        </div>
                                    )
                                )}
							{vbutton ?
                                <input type="button" onClick={verifySmartcard} className="btn-primary ctn col-sm-12" value={'PROCEED'}/>
                                :
                                <input type="button" onClick={verifySmartcard} className="btn-primary ctn col-sm-12" value={'VALIDATE'}/>
                            }
								
							{/* </button> */}
                            {/* <input type="submit" className='btn-primary lg'    value={'Login'} /> */}
							{/* </form>						 */}
                            </div>
                                            {/* <select style={{ border: 'none', background: '#f3f5f7' }}>
                                                <option value="" selected disabled>Transactions</option>
                                                <option value="">All</option>
                                                <option value="Withdrawal">Withdrawal</option>
                                                <option value="Transfer">Transfer</option>
                                                <option value="Bills">Bills</option>
                                            </select> */}
                                        </div>
                                        {/* <div>
                                            <select style={{border: 'none', background: '#f3f5f7'}}>
                                                <option value="" selected disabled>Status</option>
                                                <option value="">All</option>
                                                <option value="Success">Success</option>
                                                <option value="Failed">Failed</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="table-responsive">
                                <table className="table table-borderless dataTable">
                                    <thead>
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Fees</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">TCID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Success</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
          
  )
}
