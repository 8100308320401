import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./components/dashboard";
import Transactions from "./components/dashboard/Transaction";
import ScrollToTop from "./components/assets/ScrollTop";
import { Login } from "./components/pages/forms/Login";
import BuyAirtimenow from "./components/dashboard/BuyAirtime";
import BuyData from "./components/dashboard/BuyData";
import CableTv from "./components/dashboard/CableTv";
import Cowry from "./components/dashboard/Cowry";
import Electricity from "./components/dashboard/Electicity";
import NotFound from "./components/pages/NotFound"; // Import your 404 component
// import { Route, Routes, useNavigate } from 'react-router-dom';
import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Register } from "./components/pages/forms/Register";
import Transfer from "./components/dashboard/Transfer";
import Notification from "./components/dashboard/Notification";
import Settings from "./components/dashboard/Settings";
import AutoLogout from "./components/autoLogout";
import ComingSoon from "./components/dashboard/ComingSoon";
import Home from "./components/home";
import Signup from "./components/pages/forms/Signup";

const isTokenExpired = () => {
  const token = localStorage.getItem("lpin");
  if (!token) return true; // Token doesn't exist

  try {
    const decodedToken = token; /* Decode your token here */
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime; // Check if token expiration time is in the past
  } catch (error) {
    return true; // Token couldn't be decoded or is malformed
  }
};
// Check if the user is authenticated
const isAuthenticated = () => {
  return localStorage.getItem("lpin") !== null;
};

function App() {
  const navigate = useNavigate();
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("lpin");
    if (token) {
      const tokenExp = jwtDecode(token).exp;
      const currentTime = Date.now() / 1000;
      // console.log(currentTime);
      if (tokenExp < currentTime) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  useEffect(() => {
    checkTokenExpiration();

    // Call the function every minute (adjust the interval as needed)
    const intervalId = setInterval(checkTokenExpiration, 60000); // 60000 milliseconds = 1 minute

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [navigate]);

  return (
    <div className="">
      <ScrollToTop />
      {(isAuthenticated() && !isTokenExpired()) && <AutoLogout />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={
            isAuthenticated() ? <Navigate to="/dashboard" replace /> : <Login />
          }
        />
        <Route path="/signup" element={<Signup />} />
        {/* Protected routes */}

        <Route
          path="/transactions/*"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Transactions />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/buyairtime/*"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <BuyAirtimenow />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Dashboard />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/buydata"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <BuyData />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/electicity"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Electricity />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/cabletv"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <CableTv />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/cowrywise"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Cowry />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/transfer"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Transfer />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/notification"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Notification />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/settings"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <Settings />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/flight/comingsoon"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <ComingSoon />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/train/comingsoon"
          element={
            isAuthenticated() && !isTokenExpired() ? (
              <ComingSoon />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
