import React, { useState, useEffect, useRef } from 'react';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import Spinner from '../Spinner';
import CustomModal from '../../../Modal/makepaymentModal';

export const NotificationPage = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationsPerPage] = useState(5);
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState(false);
  
   
    // const fetchData = async () => {
    //     const token = localStorage.getItem('lpin');
    //     console.log(token)
    //     // axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
    //   try {
    //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/mynotifications`,
    //     {
    //         headers: {
    //           'x-access-token-afripayu': `Bearer ${token}`
    //         }
    //       });
           
    //      setNotifications(response.data.data);
    //     setFilteredNotifications(response.data.data);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    const fetchData = async () => {
        try {
            // setLoading(true); 
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/mynotifications`);
          const data = response.data.data;
               
         setNotifications(response.data.data);
         setFilteredNotifications(response.data.data);
        } catch (error) {
        //   if (error.response && error.response.data && error.response.data.message === "Unauthorized Token!") {
        //     // Redirect the user to the login page
        //     localStorage.removeItem("lpin");
        //     localStorage.removeItem("user");
        //     // navigate("/");
        //     <Navigate to="/" replace />
        //   } else {
        //     // Handle other errors
        //     toast.warning(message.message);
        //   }
        } finally {
            // setLoading(false); // Set loading state to false after fetching data
          }
      };
   
  
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
      filterNotifications(e.target.value);
    };
  
    const filterNotifications = (filterValue) => {
      if (!filterValue) {
        setFilteredNotifications(notifications);
      } else {
        const filtered = notifications.filter((notification) =>
          notification.message.toLowerCase().includes(filterValue.toLowerCase())
        );
        setFilteredNotifications(filtered);
      }
    };
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const openModal = (notification) => {
        console.log(notification);
      setModalData(notification);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
  
    // Logic for displaying current notifications
    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = filteredNotifications.slice(indexOfFirstNotification, indexOfLastNotification);
  
 

const Pagination = ({ itemsPerPage, totalItems, currentPage, onPageChange }) => {
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className={currentPage === number ? 'active' : ''}>
              <a href="#" onClick={() => onPageChange(number)}>
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };
  
  const Modal = ({ children, closeModal }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          {children}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // fetchWallet();
    const token = localStorage.getItem('lpin');
    if (token) {
        const cleanedToken = token.replace(/"/g, '');
        axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${cleanedToken}`;
        fetchData();
    } else {
        // navigate('/');
    }
}, []); 
  return (
    
     <section className="mainBodyContainer">
            <div className="container-fluid dashboardSummary">
              <div className="row">
                    <div className="col-sm-12">
                        <div className="materialCard materialTableContainer mt-5">
 
                            <div className="flex" style={{ }}>
                              
                            <div className="materialCardForm" style={{width: '70%'}}>
                                  
                                    <div>
                                                  <ul>
                                                    {currentNotifications.map((notification) => (
                                                    <li key={notification.id} className='card mt-2 ' onClick={() => openModal(notification)}>
                                                        
                                                        <h4  className='ml-4 title'>{notification.title}</h4>
                                                        <p className='ml-4'>{notification.message}</p>
                                                    </li>
                                                    ))}
                                                </ul>

                                                <Pagination
                                                    itemsPerPage={notificationsPerPage}
                                                    totalItems={filteredNotifications.length}
                                                    currentPage={currentPage}
                                                    onPageChange={handlePageChange}
                                                    className='pagination'
                                                    // style={}
                                                />

                                                {showModal && modalData && (
                                                    <Modal closeModal={closeModal}>
                                                    <h2>{modalData.title}</h2>
                                                    <p>{modalData.message}</p>
                                                    </Modal>
                                                )}
                                                </div>
                                    </div>
                                </div>
                            </div>

                             
                            
                        </div>
                    </div>
                </div>
           
        </section>
        
          
  )
  
}
