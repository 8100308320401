import React, { useState } from 'react';
import CustomModal from './Modal/makepaymentModal';
import PinInput from 'react-pin-input';
import { decryptData } from '../utils/util';


const PinModal = ({ isOpen, onClose, onPinValidate }) => {
  let user = null;
  // Check if localStorage item exists and decrypt it to obtain 'user' data
  const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
  if (mkLocalData) {
      const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
      const originalData = decryptData(mkLocalData, salt);
      user = JSON.parse(originalData);
  }
  // console.log(user);
  const [pin, setPin] = useState('');

  // Function to handle PIN input change
  const handlePinChange = (value) => {
    setPin(value);
    // console.log(`value: ${value}`);
  };

  // Function to handle PIN form submission
  const handleSubmit = (value, index) => {
    const isValid = validatePin(value);
    onPinValidate(isValid);
  };

  // Function to validate the entered PIN (example logic)
  const validatePin = (enteredPin) => {
    const correctPin = JSON.stringify(user.pin);
    return enteredPin === correctPin;
  };
  

  return (
  <CustomModal isOpen={isOpen} onClose={onClose} className="materialCard">
   <div className={''}>
      <div className="modal-dialog">
        
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="pinInput" className="form-label text-center">Enter PIN</label>
                {/* <input type="password" className="form-control" id="pinInput" value={pin} onChange={handlePinChange} /> */}
                

              </div>
                {/* <PinField ref={defaultProps} />; */}
                <PinInput 
                  length={4} 
                  initialValue=""
                  secret
                  secretDelay={100} 
                  onChange={(value, index) => {
                    handlePinChange(value);
                  }} 
                  type="numeric"
                  inputMode="number"
                  // style={{padding: '10px'}}  
                  // inputStyle={{borderColor: '#009898;'}}
                  // inputFocusStyle={{borderColor: '#D35A0A'}}
                  onComplete={(value, index) => {
                    handleSubmit(value, index);
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              {/* <button type="submit" className="btn btn-primary">Submit</button> */}
            </form>
          {/* </div>
        </div> */}
      </div>
    </div>
    </CustomModal>
  );
};

export default PinModal;
