import React, { useState, useEffect, useRef } from 'react';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import Spinner from '../Spinner';
import CustomModal from '../../../Modal/makepaymentModal';
import { FilePond, File, registerPlugin } from 'react-filepond'
import { Link, useNavigate, Navigate, } from 'react-router-dom';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { Avatar } from '@material-tailwind/react';
import { ProfileAvatar } from '../../../assets/Profilepic';
import { toast } from 'react-toastify';
import { decryptData } from '../../../../utils/util';

export const SettingsPage = () => {
  // constructor(props) {
  //   super(props);

    const state = {
        // Set initial files, type 'local' means this is a file
        // that has already been uploaded to the server (see docs)
        files: [
            {
                source: 'index.html',
                options: {
                    type: 'local',
                },
            },
        ],
    };
// }
    // let user = JSON.parse(localStorage.getItem("user"));
    let mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');

    const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
    const originalData = decryptData(mkLocalData, salt);
    let user = JSON.parse(originalData);

    const fullname = user.first_name + " " + user.last_name;
    const baseURL = process.env.REACT_APP_MEDIA_URL;
    const picture = 'upload/profilepic/';
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationsPerPage] = useState(5);
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()

   
    // const fetchData = async () => {
    //     const token = localStorage.getItem('lpin');
    //     console.log(token)
    //     // axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${token}`;
    //   try {
    //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/mynotifications`,
    //     {
    //         headers: {
    //           'x-access-token-afripayu': `Bearer ${token}`
    //         }
    //       });
           
    //      setNotifications(response.data.data);
    //     setFilteredNotifications(response.data.data);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    const fetchData = async () => {
        try {
            // setLoading(true); 
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/mynotifications`);
          const data = response.data.data;
               
         setNotifications(response.data.data);
         setFilteredNotifications(response.data.data);
        } catch (error) {
        //   if (error.response && error.response.data && error.response.data.message === "Unauthorized Token!") {
        //     // Redirect the user to the login page
        //     localStorage.removeItem("lpin");
        //     localStorage.removeItem("user");
        //     // navigate("/");
        //     <Navigate to="/" replace />
        //   } else {
        //     // Handle other errors
        //     toast.warning(message.message);
        //   }
        } finally {
            // setLoading(false); // Set loading state to false after fetching data
          }
      };
   
  
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
      filterNotifications(e.target.value);
    };
  
    const filterNotifications = (filterValue) => {
      if (!filterValue) {
        setFilteredNotifications(notifications);
      } else {
        const filtered = notifications.filter((notification) =>
          notification.message.toLowerCase().includes(filterValue.toLowerCase())
        );
        setFilteredNotifications(filtered);
      }
    };
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const openModal = (notification) => {
        console.log(notification);
      setModalData(notification);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };
  
    // Logic for displaying current notifications
    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = filteredNotifications.slice(indexOfFirstNotification, indexOfLastNotification);
  
 
const handleInit =() => {
      console.log("FilePond instance has initialised");
    }

const Pagination = ({ itemsPerPage, totalItems, currentPage, onPageChange }) => {
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
    
    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className={currentPage === number ? 'active' : ''}>
              <a href="#" onClick={() => onPageChange(number)}>
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };
  
  const Modal = ({ children, closeModal }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          {children}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // fetchWallet();
    const token = localStorage.getItem('lpin');
    if (token) {
        const cleanedToken = token.replace(/"/g, '');
        axios.defaults.headers.common['x-access-token-afripayu'] = `Bearer ${cleanedToken}`;
        fetchData();
    } else {
        // navigate('/');
    }
}, []); 
  return (
    <section class="mainBodyContainer">
      <div class="container-fluid dashboardSummary">
        <div class="row">
          <div class="col-sm-5 col-lg-5">
            <div class="materialCard profileSettings">
              <div class="profilePhoto">
                <img
                  src={`${baseURL + picture + user?.profile_pic?.toString()}`}
                  alt=""
                />

                {/* {user?.username ? (
                        <>
                            {user?.profile_pic ? (
                                <Avatar src={`${baseURL + picture + user.profile_pic.toString()}`} alt="profile_pic" />
                            ) : (
                                <ProfileAvatar />
                            )}
                            <h4>Welcome Back, {user.username} ! </h4>
                        </>
                    ) : null} */}
                <h4 class="mt-4">{fullname}</h4>
                <h6>{user.email}</h6>
                <div class="materialButtonIcon">
                  <span>Sign Out</span>
                  <span class="buttonIcon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.59985 23.1001H3.29989C2.66337 23.1001 2.05293 22.8403 1.60284 22.3777C1.15276 21.9151 0.899902 21.2877 0.899902 20.6335V3.36681C0.899902 2.71261 1.15276 2.08521 1.60284 1.62262C2.05293 1.16003 2.66337 0.900146 3.29989 0.900146H9.59985"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.1001 17.9999L23.1001 12L17.1001 6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23.0991 12H8.69922"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="materialCard profileSettings">
              <h5 class="mb-3">Upload Profile Picture</h5>
              {/* <input type="file" class="filepond" name="filepond" accept="image/png, image/jpeg, image/gif"/> */}
              <FilePond
                // onupdatefiles={fileItems => {
                //   // No need to handle update files here, as we'll use onprocessfile instead
                // }}
                oninit={handleInit}
                server={{
                  process: {
                    url: `${process.env.REACT_APP_BASE_URL}/user/update/${user.id}`,
                    method: "POST",
                    withCredentials: false,
                    headers: {
                      // Add any necessary headers
                    },
                    onload: (response) => {
                      let respons = JSON.parse(response);
                      //  let user = null;
                      // const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
                      // const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
                      // if (mkLocalData) {
                      //     const originalData = decryptData(mkLocalData, salt);
                      //     user = JSON.parse(originalData);
                      // }
                      // const userDataString = JSON.stringify(userDataWithoutSensitiveInfo);
                      // const encryptedData = encryptData(JSON.stringify(response.data.data), salt);
                      // localStorage.setItem("ISONU_OGBON_AWE",  encryptedData);
                      let user = JSON.parse(localStorage.getItem("user"));
                      user.profile_pic = respons.picture;
                      localStorage.setItem("user", JSON.stringify(user));
                      toast.success(respons.message);
                      navigate("/settings");
                    },
                    onerror: (error) => {
                      console.error("Error:", error);
                    },
                  },
                }}
                name="picture"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            </div>
            <div class="materialCard">
              <div class="materialCardForm">
                <h4>Update Transaction Pin</h4>
                <div class="flex-between">
                  <input
                    type="text"
                    class="subject"
                    placeholder="Old Pin"
                    id=""
                  />
                  {/* <input type="text" class="subject" placeholder="Last Name" id=""/> */}
                </div>
                <div class="flex-between">
                  <input
                    type="text"
                    class="subject"
                    placeholder="New Pin"
                    id=""
                  />
                  <input
                    type="number"
                    class="subject"
                    placeholder="Confirm New Pin"
                    id=""
                  />
                </div>
                <input type="submit" class="btn-primary" />
              </div>
            </div>
          </div>
          <div class="col-sm-7 col-lg-7">
            <div class="materialCard">
              <div class="materialCardForm">
                <h4>Update Password</h4>
                <div class="flex-between">
                  <input
                    type="text"
                    class="subject"
                    placeholder="Old Password"
                    id=""
                  />
                  {/* <input type="text" class="subject" placeholder="Last Name" id=""/> */}
                </div>
                <div class="flex-between">
                  <input
                    type="text"
                    class="subject"
                    placeholder="New Password"
                    id=""
                  />
                  <input
                    type="number"
                    class="subject"
                    placeholder="Confirm New Password"
                    id=""
                  />
                </div>
                <input type="submit" class="btn-primary" />
              </div>
            </div>

            <div className=' hidden'>
              <div class="materialCard mt-5">
                <div class="materialCardForm">
                  <h4>Business Information</h4>
                  <input
                    type="text"
                    class="subject"
                    placeholder="Business Name"
                    id=""
                  />
                  <input
                    type="text"
                    class="subject"
                    placeholder="Primary Email"
                    id=""
                  />
                  <div class="flex-between">
                    <input
                      type="text"
                      class="subject"
                      placeholder="Secondary Email"
                      id=""
                    />
                    <input
                      type="text"
                      class="subject"
                      placeholder="Secondary Email 2"
                      id=""
                    />
                  </div>
                  <div class="flex-between">
                    <input
                      type="text"
                      class="subject"
                      placeholder="Registration No"
                      id=""
                    />
                    <select>
                      <option value="" selected disabled>
                        Category
                      </option>
                      <option value="">MicroFinance Bank</option>
                      <option value="">Fintech</option>
                      <option value="">OFIs</option>
                    </select>
                  </div>
                  <div class="flex-between">
                    <input
                      type="text"
                      class="subject"
                      placeholder="City"
                      id=""
                    />
                    <input
                      type="text"
                      class="subject"
                      placeholder="State"
                      id=""
                    />
                    <input
                      type="text"
                      class="subject"
                      placeholder="LGA"
                      id=""
                    />
                  </div>
                  <div class="mt-4 mb-4">
                    <h5 class="mb-3">Upload CAC Document</h5>
                    {/* <input type="file" class="filepond" name="filepond" accept="image/png, image/jpeg, image/gif"/> */}
                    <FilePond
                      // files={this.state.files}
                      // onupdatefiles={setFiles}
                      // allowMultiple={true}
                      // maxFiles={3}
                      // server="/api"
                      name="files"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                  <div class="mt-4 mb-4">
                    <h5 class="mb-3">Upload License</h5>
                    {/* <input type="file" class="filepond" name="filepond" accept="image/png, image/jpeg, image/gif"/> */}
                    <FilePond
                      // files={this.state.files}
                      // onupdatefiles={setFiles}
                      // allowMultiple={true}
                      // maxFiles={3}
                      // server="/api"
                      name="files"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                  <input type="submit" class="btn-primary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
}
