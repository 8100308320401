import React, { useState } from "react";
import axiosPostRequest from "../../../../utils/postReq";
import CircularProgress from "@mui/material/CircularProgress";
import { networksArr, predefinedAmountsArr } from "../../../../utils";
import { NetworkProvider } from "../../../NetworkProvider";
import { PredefinedAmount } from "../../../PredefinedAmount";
import { toast } from "react-toastify";
import { PinModal } from "../../../Forms/Inputs/PinModal";

export const BuyAirtimePage = () => {
  let Ipin = JSON.parse(localStorage.getItem("lpin"));
  let business = JSON.parse(localStorage.getItem("business"));
  setInterval(() => {
    business = JSON.parse(localStorage.getItem("business"));
    Ipin = JSON.parse(localStorage.getItem("lpin"));
  }, 1000);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (value) => {
    setSelectedItem(value === selectedItem ? null : value);
    console.log(`${value}`);
  };
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [phoneno, setPhoneno] = useState(0);

  const handleAmountClick = (value) => {
    if (value < 0) return;
    setSelectedAmount(value);
    const formattedAmount = `₦${parseFloat(value).toFixed(2)}`;
    document.getElementById("amount").value = formattedAmount;
  };

  const handleAmountChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue.startsWith("0") && inputValue.length > 1) {
      inputValue = inputValue.slice(1);
    }
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setSelectedAmount();
    }
  };

  const [ourMessage, setOurMessage] = useState("");

  const [successful, setSuccessful] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const [pinErrorLabel, setPinErrorLabel] = useState("");

  //   console.log("PINNNNNNNNNNNNNNNN", typeof pinValue);
  //   console.log("User pin ", typeof user?.pin);

  const handleIsPinClose = () => {
    setIsPin(false);
  };

  const formatCurrency = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) return "";
    return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
  };

  const headers = {
    Accept: "application/json",
    "x-access-token-afripayu": `Bearer ${Ipin}`,
  };

  const data = {
    amount: selectedAmount,
    network: selectedItem,
    phoneno: phoneno,
  };

  const url = "https://api.getmopal.com/api/user/bill/airtime";

  const Submit = () => {
    setIsLoading(true);
    axiosPostRequest(url, data, headers)
      .then((responseData) => {
        console.log(responseData);
        setOurMessage(responseData.message);
        if (responseData.success === true) {
          setSuccessful(true);
          setIsOpen(true);
        } else {
          //   setFailed(true);
          setIsOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ToastSucess = () => {
    toast.success("Transaction successfull");
    setIsOpen(false);
  };
  const ToastFail = () => {
    toast.error("Transaction failed");
    setIsOpen(false);
  };

  //   console.log("**SUCess", successful);
  //   console.log("**Messa", ourMessage);
  //   console.log("***DATA", data);
  //   console.log("**HEaders", headers);

  return (
    <section className="mainBodyContainer">
      <div className="container-fluid dashboardSummary">
        <div className="row">
          <div className="col-sm-12">
            <div className="materialCard materialTableContainer mt-5">
              <div className="flex-between" style={{}}>
                <div
                  className="materialCardForm mx-auto flex flex-col justify-center items-center"
                  //   style={{ width: "70%" }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label
                        for="network"
                        className=" font-medium text-base text-center tablet:text-left"
                      >
                        Select network provider
                      </label>
                      <div className="lscrolling-wrapper grid grid-cols-1 tablet:grid-cols-3">
                        {networksArr.map((network, i) => (
                          <NetworkProvider
                            key={i}
                            onclick={() => handleItemClick(network.value)}
                            name={network.name}
                            value={network.value}
                            src={network.imageUrl}
                            selectedItem={selectedItem}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className=" font-medium text-base text-center tablet:text-left">
                        Phone number
                      </label>
                      <input
                        placeholder="Enter Phone Number"
                        name="phone"
                        id="phone"
                        className="form-control min-w-full rounded-sm"
                        value={phoneno}
                        onChange={(e) => setPhoneno(e.target.value)}
                        maxLength={11}
                      />
                    </div>

                    <div className="form-group">
                      <label className=" font-medium text-base text-center tablet:text-left">
                        Amount
                      </label>
                      <div className="lscrolling-wrapper my-2 grid grid-cols-1 tablet:grid-cols-3">
                        {predefinedAmountsArr.map((amount, i) => (
                          <PredefinedAmount
                            key={i}
                            amount={amount.amount}
                            price={amount.price}
                            onclick={() => handleAmountClick(amount.amount)}
                            selectedAmount={selectedAmount}
                          />
                        ))}
                      </div>
                      <input
                        className="form-control min-w-full rounded-sm"
                        type="text"
                        name="amount"
                        id="amount"
                        value={selectedAmount}
                        onChange={handleAmountChange}
                        placeholder="Amount"
                      />
                    </div>

                    <input
                      type="hidden"
                      name="network"
                      id="network"
                      value="9mobile"
                    />
                    <input type="hidden" name="postType" value="single" />
                    <input
                      type="hidden"
                      name="discount"
                      id="discount"
                      value="3"
                    />
                    <input
                      type="hidden"
                      name="network_id"
                      id="network_id"
                      value="23"
                    />
                    {/* <button
                      type="submit"
                      onClick={() => {
                        setIsPin(true);
                      }}
                      //   className=" h-10 bg-green-500 w-full font-medium text-base text-white outline-none ring-0 border-0 focus:outline-none focus:ring-0"
                      className="btn-primary ctn col-sm-12"
                    >
                      {isLoading ? <CircularProgress /> : "PROCEED"}
                    </button> */}
                    <input
                      type="button"
                      onClick={() => {
                        setIsPin(true);
                      }}
                      className="btn-primary ctn col-sm-12"
                      value={"PROCEED"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPin && (
        <PinModal
          isPin={isPin}
          handleIsPinClose={handleIsPinClose}
          pinErrorLabel={pinErrorLabel}
          pinValue={pinValue}
          onChn={(value, index) => {
            setPinValue(value);
          }}
          onSuc={() => {
            Submit();
            setPinValue("");
            handleIsPinClose();
          }}
        />
      )}

      {successful && isOpen && ToastSucess()}
      {!successful && isOpen && ToastFail()}
    </section>
  );
};
