// import { Breadcrumbs } from '@material-tailwind/react'
// import React from 'react'
// import React, { useState } from 'react';
import React, { useState, useEffect } from "react";
import axios from "axios";

// import { Link } from 'react-router-dom'
// import { TbTruckDelivery, TbPackgeExport } from "react-icons/tb"
// import { AiOutlineDeliveredProcedure } from "react-icons/ai"
// import {GiMoneyStack } from "react-icons/gi"
// import { TbLiveView} from "react-icons/tb"
// import AdminChart from '../../assets/Charts/AdminOrders'
// import UsersChart from '../../assets/Charts/UserChart'
// import { FaUsers } from 'react-icons/fa'
// import { MdOutlineInventory } from 'react-icons/md'
// import { InventDashBoardHomeTable } from '../../assets/Tables/DbInventoey'
import numeral from "numeral";
import { NumericFormat } from "react-number-format";
import Spinner from "../Spinner";
// public/assets/img/billassets/img/bill/9mobile.png
import mobile from "../../../../images/bill/9mobile.png";
import airtel from "../../../../images/bill/airtel.png";
import mtn from "../../../../images/bill/mtn.png";
import glo from "../../../../images/bill/glo.png";
import PinModal from "../../../pincheck";
import ScuccessModal from "../../../success";
import { toast } from "react-toastify";
import BuyData from "../../BuyData";
import { Link, useNavigate } from "react-router-dom";

export const BuyDataPage = () => {
  const navigate = useNavigate();

  let business = JSON.parse(localStorage.getItem("business"));
  setInterval(() => {
    business = JSON.parse(localStorage.getItem("business"));
  }, 1000);
  const [loading, setLoading] = useState(false);
  const stopLoading = () => setLoading(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [planId, setPlanID] = useState(null);
  const [planamount, setPlanAmount] = useState(null);
  const [vbutton, setvButton] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSuccess, setIsModalSuccess] = useState(false);
  const [phone_number, setPhonenumber] = useState(null);

  // const handleItemClick = (value) => {
  //     setSelectedItem(value === selectedItem ? null : value);
  //     console.log(`${value}`);

  // };
  const handleItemClick = async (item) => {
    setLoading(true);
    setSelectedItem(item);
    // console.log(`${item}`);
    // setSelectedItem(item === selectedItem ? null : item);
    // Fetch data based on the selected item using Axios
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/bills/datalookup`,
        { network: item }
      );
      console.log(response.data.data);
      setItemData(response.data.data);
      stopLoading();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [selectedAmount, setSelectedAmount] = useState(null);

  const selectDataPlan = (item) => {
    const plancodes = item.plan_code;
    const amount = item.amount;
    const pname = item.name;
    setPlanID(plancodes);
    setPlanAmount(amount);
  };

  const handlePhoneChange = (event) => {
    let inputValue = event.target.value;
    // console.log(inputValue.length);
    if (inputValue.length > 10 || inputValue.length === 11) {
      setPhonenumber(inputValue);
    }
  };

  const formatCurrency = (value) => {
    const floatValue = parseFloat(value);
    if (isNaN(floatValue)) return "";
    return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
  };

  // const handleAmountChange = (event) => {
  //     const inputValue = event.target.value;
  //     if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') { // Allow only numeric input or empty string
  //         setSelectedAmount(formatCurrency(inputValue)); // Format the input value
  //     }
  // };

  // const formatCurrency = (value) => {
  //     const floatValue = parseFloat(value);
  //     if (isNaN(floatValue)) return ''; // If value is not a valid number, return empty string
  //     return `₦${floatValue.toFixed(2)}`; // Format amount with Nigeria Naira symbol and two decimal places
  // };
  // Function to handle PIN validation result
  const BuyDataMopal = async () => {
    try {
      setLoading(true);
      // console.log(receiverid)
      // console.log('ereach herer')

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/buydata`,
        {
          amount: planamount,
          plancode: planId,
          network: selectedItem,
          phonenumber: phone_number,
        }
      );
      const data = response.data;
      //   console.log(data);
      //   setResponse(response.data.data);
      const success = data.success;
      const mssg = data.message;
      if (success) {
        openModalSuccess();
        toast.success(mssg);
        // navigate('/transactions')
      } else {
        console.log(data.message);
        toast.warning(mssg);
      }
      stopLoading();
      setvButton(true);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false); // Set loading state to false after fetching data
    }
  };
  const handlePinValidate = (isValid) => {
    if (isValid) {
      closeModal();
      toast.success("Correct PIN");
      BuyDataMopal();
    } else {
      toast.error("PIN is incorrect");
    }
  };
  const handleSucees = (isValid) => {
    if (isValid) {
      // closeModal()
      // console.log('PIN is correct');
      // toast.success('Correct PIN');
      // transferMopal();
      // Perform actions for correct PIN
    } else {
      toast.error("PIN is incorrect");
      // console.log('PIN is incorrect');
      // Perform actions for incorrect PIN
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModalSuccess = () => {
    navigate("/transactions");
    setIsModalSuccess(false);
  };
  const openModalSuccess = () => {
    setIsModalSuccess(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("lpin").replace(/"/g, "");
    axios.defaults.headers.common[
      "x-access-token-afripayu"
    ] = `Bearer ${token}`;
  }, []);

  return (
    <section className="mainBodyContainer">
      <div className="container-fluid dashboardSummary">
        <div className="row">
          <div className="col-sm-12">
            <div className="materialCard materialTableContainer mt-5">
              <div className="flex-between" style={{}}>
                <div className="materialCardForm" style={{ width: "100%" }}>
                  <div className="">
                    <div className=" tablet:px-5 tablet:ml-5">
                      <div className="card-body">
                        {/* <form onSubmit={}> */}
                        <div className="form-group">
                          <label for="network">Select network provider</label>
                          <div className="scrolling-wrapper">
                            <div
                              className={`network-item border-1 9mobile ${
                                selectedItem === "9mobile" ? "selected" : ""
                              }`}
                              onClick={() => handleItemClick("9mobile")}
                            >
                              <span className="mr-2">
                                <img
                                  className="network-img"
                                  src={mobile}
                                  alt="9mobile Airtime"
                                />
                              </span>
                              <span className="network-name">9MOBILE</span>
                              <span></span>
                              {/* {selectedItem === '9mobile' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />} */}
                            </div>
                            <div
                              className={`network-item border-1 glo ${
                                selectedItem === "glo" ? "selected" : ""
                              }`}
                              onClick={() => handleItemClick("glo")}
                            >
                              <span className="mr-2">
                                <img
                                  className="network-img"
                                  src={glo}
                                  alt="glo Airtime"
                                />
                              </span>
                              <span className="network-name">Glo</span>
                              <span></span>
                              {/* {selectedItem === 'glo' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />} */}
                            </div>
                            <div
                              className={`network-item border-1 airtel ${
                                selectedItem === "airtel" ? "selected" : ""
                              }`}
                              onClick={() => handleItemClick("airtel")}
                            >
                              <span className="mr-2">
                                <img
                                  className="network-img"
                                  src={airtel}
                                  alt="airtel Airtime"
                                />
                              </span>
                              <span className="network-name">Airtel</span>
                              <span></span>
                              {/* {selectedItem === 'airtel' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />} */}
                            </div>
                            <div
                              className={`network-item border-1 mtn ${
                                selectedItem === "mtn" ? "selected" : ""
                              }`}
                              onClick={() => handleItemClick("mtn")}
                            >
                              <span className="mr-2">
                                <img
                                  className="network-img"
                                  src={mtn}
                                  alt="mtn Airtime"
                                />
                              </span>
                              <span className="network-name">Mtn</span>
                              <span></span>
                              {/* {selectedItem === 'mtn' && <input type="checkbox"  className="rounded-checkbox"  checked={true} readOnly />} */}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          {loading ? (
                            <Spinner />
                          ) : (
                            <div class="form-group y-scroll">
                              <table
                                className="table data-table table-striped table-hover table-bordered"
                                style={{ fontSize: 13 }}
                              >
                                {itemData.length > 0 ? (
                                  itemData.map((item, index) => (
                                    <tbody key={index} className="data-body">
                                      <tr>
                                        <td align="center">
                                          <input
                                            title={item.name}
                                            type="radio"
                                            name="plan"
                                            required
                                            onChange={() =>
                                              selectDataPlan(item)
                                            } // Added onChange event handler
                                          />
                                        </td>
                                        <td>
                                          <img
                                            className="network-img"
                                            src={
                                              selectedItem === "mtn"
                                                ? mtn
                                                : selectedItem === "glo"
                                                ? glo
                                                : selectedItem === "airtel"
                                                ? airtel
                                                : mobile
                                            }
                                          />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{formatCurrency(item.amount)}</td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p></p>
                                )}
                              </table>
                            </div>
                          )}
                          <label>Phone number</label>
                          <input
                            placeholder="Enter Phone Number"
                            name="phone"
                            id="phone"
                            className="form-control"
                            maxLength={11}
                            minLength={11}
                            onChange={handlePhoneChange}
                          />
                          {/* <div className="d-flex justify-content-between align-items-center">
									<span>
										<a href="#" className="multipleNetwork text-primary text-sm" data-product="2" data-toggle="modal" data-target="#multipleNetwork">
											Don't know the networks?
										</a>
									</span>
									<span><a href="#" className="uploadMultiple text-primary text-sm" data-product="2" data-toggle="modal" data-target="#uploadMultiple">Upload CSV</a>
									</span>
								</div> */}
                        </div>
                        <div className="form-group m-scroll" style={{}}>
                          <table
                            className="table table-striped table-bordered"
                            id="contact-list"
                          >
                            <tbody className="contact-body"></tbody>
                          </table>
                        </div>

                        <input
                          type="button"
                          onClick={openModal}
                          className="btn-primary ctn col-sm-12"
                          value={"PROCEED"}
                        />
                        <PinModal
                          isOpen={isModalOpen}
                          onClose={closeModal}
                          onPinValidate={handlePinValidate}
                        />
                        <ScuccessModal
                          isOpen={isModalOpenSuccess}
                          onClose={closeModalSuccess}
                          onPinValidate={handleSucees}
                        />

                        {/* <input type="button" onClick={openModal} className="btn-primary ctn col-sm-12" value={'PROCEED'}/>
                                <PinModal isOpen={isModalOpen} onClose={closeModal} onPinValidate={handlePinValidate} />
                                <ScuccessModal isOpen={isModalOpenSuccess} onClose={closeModalSuccess} onPinValidate={handleSucees} /> */}
                        {/* <input type="button" onclick="previewTransaction('airtime')" data-wallet="8499.00|0.00" data-toggle="modal" data-target="#openPreview" data-backdrop="static" data-keyboard="false" className="btn-primary ctn col-sm-12" value={'PROCEED'}/> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="table-responsive">
                                <table className="table table-borderless dataTable">
                                    <thead>
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Fees</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">TCID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Success</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Witddrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    <tr>
                                        <td>Victor O.</td>
                                        <td>2243434322</td>
                                        <td>To Eben</td>
                                        <td>15000</td>
                                        <td>100</td>
                                        <td>Withdrawal</td>
                                        <td>12C4</td>
                                        <td>Failed</td>
                                        <td>May 2</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
