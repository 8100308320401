import React, { useState } from "react";
import CustomModal from "./Modal/makepaymentModal";

const ActionColumn = ({ rowData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <CustomModal
          isOpen={showModal}
          onClose={closeModal}
          className="materialCard"
        >
          {/* Render modal content with selectedItem data */}
          {/* <div key={selectedItem.id}>
            <h2>Selected Item Data</h2>
            <p>ID: {selectedItem.reference}</p>
           </div> */}
          {/* <div class="materialModalContainer viewTransactionDetailsDiv" data-trigger-content="viewTransactions"> */}
          <div class="materialModalDiv max-w-[300px] w-full tablet:max-w-full flex flex-col gap-2">
            <div class="materialModalHeader">
              <div>
                <h4>Transactions</h4>
              </div>
              <div class="closeModal" onClick={closeModal}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 1.5L22.8627 22.5627"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.8625 1.5L1.49986 22.5627"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div class="transactionDetails flex flex-col tablet:flex-row justify-between items-start tablet:items-center gap-x-4 tablet:gap-x-6 lflex-between mb-4">
              <div>
                <small>Amount</small>
                <h5>NGN {selectedItem.amount}</h5>
              </div>
              <div className=" text-start tablet:text-end">
                <small>Original Amount</small>
                <h5>NGN {selectedItem.amount}</h5>
              </div>
            </div>

            <div class="transactionDetails flex flex-col tablet:flex-row justify-between items-start tablet:items-center gap-x-4 tablet:gap-x-6 lflex-between mb-4">
              <div>
                <small>Amount Charged</small>
                <h5>NGN {selectedItem.amount}</h5>
              </div>
              <div className=" text-start tablet:text-end">
                <small>Service Fee</small>
                <h5>0.0%</h5>
              </div>
            </div>

            <div class="transactionDetails flex flex-col tablet:flex-row justify-between items-start tablet:items-center gap-x-4 tablet:gap-x-6 lflex-between mb-4">
              <div>
                <small>Status</small>
                <h5
                  className={
                    selectedItem.status == 1
                      ? "text-green-500"
                      : " text-red-700"
                  }
                >
                  Payment {selectedItem.status == 1 ? "Success" : "Failed"}
                </h5>
              </div>
              <div className=" text-start tablet:text-end">
                <small>Type</small>
                <h5>{selectedItem.payment_method}</h5>
              </div>
            </div>

            <div class="transactionDetails flex flex-col tablet:flex-row justify-between items-start tablet:items-center gap-x-4 tablet:gap-x-6 lflex-between mb-4">
              <div>
                <small>Transaction Date</small>
                <h5>{selectedItem.createdAt}</h5>
              </div>
              <div className=" text-start tablet:text-end">
                <small>Reference</small>
                <h5>{selectedItem.reference}</h5>
              </div>
            </div>

            {/* <div class="transactionDetails flex-between mb-4">
                    <div><small>Method</small><h5>CARD</h5></div>
                    <div><small>Card Expiry</small><h5>23/04</h5></div>
                </div> */}
          </div>
          {/* </div> */}
        </CustomModal>
      )}
      <div className="" data-trigger-id="">
        <button
          type="button"
          class="btn orange-theme btn-sm"
          onClick={() => handleClick(rowData)}
        >
          Detail
        </button>
        {/* <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => handleClick(rowData)}
          style={{ cursor: 'pointer' }}
        >
        <path d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"></path>
        </svg> */}
      </div>
    </>
  );
};

export default ActionColumn;
