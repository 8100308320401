import React from 'react';

const TimeAgo = ({ timestamp }) => {
  const currentTime = new Date(); 
  const providedTime = new Date(timestamp);  

  const difference = currentTime - providedTime;
  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  let agoString = '';
  if (years > 0) {
    agoString += `${years} year${years > 1 ? 's' : ''} `;
  } else if (months > 0) {
    agoString += `${months} month${months > 1 ? 's' : ''} `;
  } else if (days > 0) {
    agoString += `${days} day${days > 1 ? 's' : ''} `;
  } else if (hours > 0) {
    agoString += `${hours} hour${hours > 1 ? 's' : ''} `;
  } else if (minutes > 0) {
    agoString += `${minutes} minute${minutes > 1 ? 's' : ''} `;
  } else {
    agoString += `${seconds} second${seconds > 1 ? 's' : ''} `;
  }

  return <span className=' text-sm'>{agoString} ago</span>;
};

export default TimeAgo;
