import React, { useEffect, useState } from "react";
// import React, { useState } from 'react';
import {
  MenuIcon,
  NotificationIcon,
  ProfileIcon,
  SettingsIcon,
} from "../../Icons";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Avatar,
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { FaBell, FaInfoCircle } from "react-icons/fa";
// import { useDispatch } from "react-redux";
// import { logout } from "../../../store/slices/auth";
import { ProfileAvatar } from "../../assets/Profilepic";
import toString from "../../../../node_modules/lodash-es/toString";
import axios from "axios";
import TimeAgo from "../../Timeago";
import MessageCounter from "../../count";
import { decryptData } from "../../../utils/util";
import CustomModal from "../../Modal/makepaymentModal";
import { Button, Drawer } from "@mui/material";
import { ListAndHighlight } from "../../Navigation/NavElements/ListAndHighlight";
import { sidebarItems } from "../../../utils";

// let mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');

// const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
// const originalData = decryptData(mkLocalData, salt);
// console.log(originalData);
// console.log('originalData');
// if(!originalData){
//   // will executes if someone altered the code in localstorage.
// }

// let mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
// if (mkLocalData) {
//     const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
//     const originalData = decryptData(mkLocalData, salt);
//     let user = JSON.parse(originalData);
//     // Use the user data as needed
// } else {
//     // Handle the case where the localStorage item is null
//     console.error('LocalStorage item is null');
// }
// let user = null;

// let user = null;
// const mkLocalData = localStorage.getItem('ISONU_OGBON_AWE');
// if (mkLocalData) {
//     const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
//     const originalData = decryptData(mkLocalData, salt);
//     user = JSON.parse(originalData);
// }

let user = JSON.parse(localStorage.getItem("user"));
// let user = JSON.parse(localStorage.getItem("ISONU_OGBON_AWE"));
const baseURL = process.env.REACT_APP_MEDIA_URL;
const picture = "upload/profilepic/";
export default function Header({ toggleSidebar }) {
  console.log(toggleSidebar);
  const [isActive, setIsActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (item) => {
    toggleActive();
    setSelectedItem(item);
    readNotifi(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };

  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // eslint-disable-next-line
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const checkUser = () => {
    if (user.id === null || user.id === undefined) {
      navigate("/");
    }
  };
  setInterval(() => {
    user = JSON.parse(localStorage.getItem("user"));
  }, 1000);

  const openNotification = () => {
    navigate("/notification");
  };

  const openSettings = () => {
    navigate("/settings");
  };
  function trimText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  }
  const readNotifi = async (item) => {
    try {
      // setLoading(true);
      console.log(item.id);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/notificationread`,
        { id: item.id }
      );
      const data = response.data.data;
      console.log(data);
      fetchData();
      //  setNotifications(response.data.data);
      //  setFilteredNotifications(response.data.data);
    } catch (error) {
    } finally {
    }
  };
  const fetchData = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/mynotifications`
      );
      const data = response.data.data;

      setNotifications(response.data.data);
      setFilteredNotifications(response.data.data);
    } catch (error) {
    } finally {
    }
  };
  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = filteredNotifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );
  useEffect(() => {
    // fetchWallet();
    const token = localStorage.getItem("lpin");
    if (token) {
      const cleanedToken = token.replace(/"/g, "");
      axios.defaults.headers.common[
        "x-access-token-afripayu"
      ] = `Bearer ${cleanedToken}`;
      fetchData();
    } else {
      // navigate('/');
    }
  }, []);

  //   console.log("Notfi", notifications);
  //   console.log("FilNotfi", filteredNotifications);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  return (
    <section className="mainBarNavContainer">
      <div className="materialCard mainBarNavDiv">
        <div className="mainBarNavProfile">
          <div className="sideBarBtn tablet:hidden">
            <>
              <Button onClick={toggleDrawer(true)}>
                <MenuIcon />
              </Button>
              <Drawer
                anchor={"left"}
                open={isOpen}
                onClose={toggleDrawer(false)}
                className=" min-h-screen"
                sx={{ height: "100vh" }}
              >
                <div className=" min-w-[200px] py-4">
                  <div className=" w-full flex justify-center mb-4">
                    <img
                      src="/assets/img/favicon.png"
                      style={{ width: 90 }}
                      alt=""
                    />
                  </div>
                  <ListAndHighlight
                    itemsArr={sidebarItems}
                    customStyles={" flex gap-4 py-3"}
                    onclickAction={toggleDrawer(false)}
                  />
                </div>
              </Drawer>
            </>
          </div>
          {/* <div className="sideBarBtn" onClick={toggleSidebar}>
            <svg
              className="showOnTablet showOnMobile"
              width="28"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 12H22.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.5 21H22.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.5 3H22.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> */}
          {/* <img src="assets/img/user.png" alt=""/> */}
          {/* <a href="settings.html"><h4>Hello Eben,</h4></a> */}
          {user?.username ? (
            <>
              {user?.profile_pic ? (
                <Avatar
                  src={`${baseURL + picture + user.profile_pic.toString()}`}
                  alt="profile_pic"
                />
              ) : (
                <ProfileAvatar />
              )}
              <h4>Welcome Back, {user.username} ! </h4>
            </>
          ) : null}
        </div>
        {/* {user.id} */}
        <div className="flex-between">
          <div className="notifications">
            <NotificationIcon onclick={toggleActive} />
            <span className="badge">
              <MessageCounter responseData={notifications} />
            </span>

            {/* <div className={'notificationsList'}> */}
            <div
              className={`notificationsList ${
                isActive ? "active" : ""
              } max-h-[500px] overflow-y-scroll`}
            >
              <div className="notificationHeader flex-between">
                <div>
                  <h5>Notifications</h5>
                </div>
                <div className="closeNotifications" onClick={toggleActive}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
                    ></path>
                  </svg>
                </div>
              </div>
              {notifications.map((notification) => (
                <div
                  key={notification.id}
                  onClick={() => handleClick(notification)}
                >
                  <div className="">
                    <h5
                      className={` ${
                        notification.read == "0"
                          ? "text-red-500"
                          : " text-gray-500"
                      }`}
                    >
                      {notification.title}
                    </h5>
                    <p
                      className={` ${
                        notification.read == "0"
                          ? "text-red-500"
                          : " text-gray-500"
                      }`}
                    >
                      {trimText(notification.message, 24)}
                    </p>
                  </div>
                  <h6
                    className={` ${
                      notification.read == "0"
                        ? "text-red-500"
                        : " text-gray-500"
                    } hidden tablet:block`}
                  >
                    <TimeAgo timestamp={notification.createdAt} />{" "}
                  </h6>
                </div>
              ))}

              {showModal && (
                <CustomModal
                  isOpen={showModal}
                  onClose={closeModal}
                  className="materialCard"
                >
                  {/* Render modal content with selectedItem data */}
                  <div key={selectedItem.id}>
                    {/* <h2>Selected Item Data</h2>
                                                            <p>ID: {selectedItem.title}</p>
                                                            Add more data fields as needed */}
                    <div class="materialModalDiv">
                      <div class="materialModalHeader">
                        <div>
                          <h3>{selectedItem.title}</h3>
                        </div>
                        <div class="closeModal" onClick={closeModal}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1.5L22.8627 22.5627"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M22.8625 1.5L1.49986 22.5627"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <p>
                        <small>Sent to: {user?.email}</small>
                      </p>
                      <p>
                        Dear{" "}
                        <span className="text-deep-orange-800 font-bold">
                          {" "}
                          {user?.first_name}
                        </span>{" "}
                        , below message is for you!.
                      </p>
                      <p>{selectedItem.message}</p>
                      <p>
                        <small>
                          <TimeAgo timestamp={selectedItem.createdAt} />{" "}
                        </small>
                      </p>
                      <p></p>
                    </div>
                  </div>
                </CustomModal>
              )}

              {/* <div>
                                <h5>Ticket ID was closed recently.</h5>
                                <h6>May 27</h6>
                            </div>
                            <div>
                                <h5>Terminal ID 123DGB5 has been mapped.</h5>
                                <h6>May 27</h6>
                            </div> */}
            </div>
          </div>

          <div>
            <svg
              className="settings"
              onClick={openSettings}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* <div className="materialCard mainBarNavDiv">
            <div className="mainBarNavProfile">
                    <div className="sideBarBtn">
                        <svg className="showOnTablet showOnMobile" width="28" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 12H22.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.5 21H22.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.5 3H22.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                    {user?.username ? (
                        <>
                            {user?.profile_pic ? (
                                <Avatar src={`${baseURL + picture + user.profile_pic.toString()}`} alt="profile_pic" />
                            ) : (
                                <ProfileAvatar />
                            )}
                            <h4>Welcome Back, {user.username} ! </h4>
                        </>
                    ) : null}
                </div> */}

      {/* <div>
                    <svg className="notification" onClick={openNotification} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.3755 16.5765C20.5348 15.678 18.9612 14.3266 18.9612 9.90014C18.9627 8.31165 18.4072 6.7723 17.3904 5.54694C16.3735 4.32158 14.9589 3.48682 13.3898 3.1863V2.28476C13.3898 2.10293 13.3538 1.92288 13.2838 1.75489C13.2138 1.5869 13.1112 1.43426 12.9818 1.30569C12.8525 1.17712 12.6989 1.07513 12.5299 1.00554C12.361 0.935961 12.1798 0.900146 11.9969 0.900146C11.814 0.900146 11.6329 0.935961 11.4639 1.00554C11.2949 1.07513 11.1414 1.17712 11.012 1.30569C10.8827 1.43426 10.7801 1.5869 10.7101 1.75489C10.6401 1.92288 10.6041 2.10293 10.6041 2.28476V3.1863C9.03499 3.48682 7.62033 4.32158 6.60347 5.54694C5.58662 6.7723 5.03117 8.31165 5.03267 9.90014C5.03267 14.3266 3.45906 15.678 2.6184 16.5765C2.37861 16.8307 2.24668 17.167 2.25006 17.5155C2.25023 17.6977 2.28651 17.878 2.35683 18.0462C2.42716 18.2144 2.53015 18.3672 2.65991 18.4958C2.78968 18.6244 2.94367 18.7263 3.11309 18.7957C3.2825 18.865 3.46402 18.9005 3.64725 18.9001H20.3528C20.536 18.9005 20.7175 18.865 20.887 18.7957C21.0564 18.7263 21.2104 18.6244 21.3401 18.4958C21.4699 18.3672 21.5729 18.2144 21.6432 18.0462C21.7135 17.878 21.7498 17.6977 21.75 17.5155C21.7517 17.1662 21.6175 16.8297 21.3755 16.5765Z"/><path d="M10.5 1.65015H13.5V3.15015L12.75 3.97515H12H11.25L10.5 3.15015V1.65015Z" fill="#51526C"/><path d="M15 21C15 21.7956 14.6839 22.5587 14.1213 23.1213C13.5587 23.6839 12.7956 24 12 24C11.2044 24 10.4413 23.6839 9.87868 23.1213C9.31607 22.5587 9 21.7956 9 21L12 21H15Z" fill="#51526C"/></svg>
                    <svg className="settings" onClick={openSettings} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div> */}
      {/* </div> */}
    </section>
    // <div className="fixed w-full z-50  bg-white">
    //     <div className="flex w-full justify-between lg:head-grid">
    //         <div className="bg-white py-1  text-center">
    //             <Link to="/"><img src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1671791124/5mlog/new-logo_d5wzz4.png" alt="boglogo" className="w-18 xl:w-20 lg:h-20 h-18 mx-auto" /></Link>
    //         </div>
    //         <div className="lg:shadow bg-white py-2 px-5 flex lg:justify-between justify-end  items-center">

    //             <div className="ml-10 hidden lg:flex items-center w-6/12">
    //                 {/* <FontAwesomeIcon icon={faBarsStaggered} size="2x" className="text-2xl lg:ml-4 cursor-pointer" onClick={sidebarReduce}/> */}
    //                 <p className="ml-5 fw-700 hidden lg:block"> Dasboard</p>

    //             </div>
    //             <div className="flex items-center justify-end w-full">
    //                 {/* <div className="mr-6 relative mx-auto text-gray-600 hidden lg:block">
    //                     <input className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
    //                         type="search" name="search" placeholder="Search" />
    //                     <button type="submit" className="absolute bg-primary right-0 top-0 py-2 px-4 rounded-r-lg">
    //                         <FontAwesomeIcon icon={faSearch} className="text-white" />
    //                     </button>
    //                 </div> */}
    //                 <div className="ml-5">
    //                     <Menu placement="bottom-end"
    //                     >
    //                         <MenuHandler>
    //                             <Button className="p-2 bg-transparent shadow-none">
    //                                 <div className="bg-gray-100 px-2 rounded-sm py-2 relative">
    //                                     <FaBell className="lg:text-xl text-lg text-primary" />
    //                                     <p className="absolute -top-2 left-3/4 border circle px-1 text-white text-xs bg-primary">4</p>
    //                                 </div>
    //                             </Button>
    //                         </MenuHandler>
    //                         <MenuList className="lg:w-60">
    //                             <MenuItem>
    //                                 <div className="flex">
    //                                     <span className="mt-1 text-primary pr-2"><FaInfoCircle/></span>
    //                                     <p>A new order from a Customer</p>
    //                                 </div>
    //                             </MenuItem>
    //                             <MenuItem>
    //                                 <div className="flex">
    //                                     <span className="mt-1 text-primary pr-2"><FaInfoCircle/></span>
    //                                     <p>A new order from a Partner</p>
    //                                 </div>
    //                             </MenuItem>
    //                             <MenuItem>
    //                                 <div className="flex">
    //                                     <span className="mt-1 text-primary pr-2"><FaInfoCircle/></span>
    //                                     <p>Request to deliver a product has been made</p>
    //                                 </div>
    //                             </MenuItem>
    //                             <MenuItem>
    //                                 <div className="flex">
    //                                     <span className="mt-1 text-primary pr-2"><FaInfoCircle/></span>
    //                                     <p>Request to deliver a product has been made</p>
    //                                 </div>
    //                             </MenuItem>
    //                         </MenuList>
    //                     </Menu>
    //                 </div>
    //                 <div className="ml-5">
    //                     <Menu placement="bottom-end"
    //                     >
    //                         <MenuHandler>
    //                             <Button className="p-0">
    //                                 {user?.photo? <Avatar src={`${process.env.REACT_APP_MEDIA_URL + user?.photo}`}
    //                             alt="profifepic" /> : <ProfileAvatar/>}
    //                             </Button>
    //                         </MenuHandler>
    //                         <MenuList>
    //                             <MenuItem onClick={() => navigate("/dashboard/pickup")}>Logistics</MenuItem>
    //                             <MenuItem onClick={() => navigate("/dashboard/freight")}>Freights</MenuItem>
    //                             <MenuItem onClick={() => navigate("/dashboard/orders")}>My Orders</MenuItem>
    //                             <MenuItem onClick={() => navigate("/dashboard/settings")}>Settings</MenuItem>
    //                             {/* <MenuItem onClick={()=> dispatch(logout())}>Sign Out</MenuItem> */}
    //                         </MenuList>
    //                     </Menu>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
}

// import React, { useEffect, useState } from "react";
// import {
//     Menu, MenuHandler, MenuItem, MenuList, Button, Avatar,
// } from "@material-tailwind/react";
// import { Link, useNavigate } from "react-router-dom";
// import { FaBell, FaInfoCircle } from "react-icons/fa";
// // import { useDispatch } from "react-redux";
// // import { logout } from "../../../store/slices/auth";
// import { ProfileAvatar } from "../../assets/Profilepic";
// import toString from '../../../../node_modules/lodash-es/toString';
// import CustomModal from '../../Modal/makepaymentModal';

// const baseURL = process.env.REACT_APP_MEDIA_URL;
// const picture = 'upload/profilepic/';

// export default function Header() {
//     // eslint-disable-next-line
//     const navigate = useNavigate()
//     // const dispatch = useDispatch();
//     const [user, setUser] = useState(null);

//     const checkUser = () => {
//         const storedUser = JSON.parse(localStorage.getItem("user"));
//         if (storedUser && storedUser.id !== null && storedUser.id !== undefined) {
//             setUser(storedUser);
//         } else {
//             navigate('/');
//         }
//     };

//     useEffect(() => {
//         checkUser();
//     }, []);

//     return (
//         <section className="mainBarNavContainer">
//             <div className="materialCard mainBarNavDiv">
//                 <div className="mainBarNavProfile">
//                     <div className="sideBarBtn">
//                         <svg className="showOnTablet showOnMobile" width="28" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 12H22.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.5 21H22.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.5 3H22.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
//                     </div>
//                     {user?.username ? (
//                         <>
//                             {user?.profile_pic ? (
//                                 <Avatar src={`${baseURL + picture + user.profile_pic.toString()}`} alt="profile_pic" />
//                             ) : (
//                                 <ProfileAvatar />
//                             )}
//                             <h4>Welcome Back, {user.username} ! </h4>
//                         </>
//                     ) : null}
//                 </div>
//                 <div>
//                     {/* Notification and Settings icons */}
//                 </div>
//             </div>
//         </section>
//     );
// }
