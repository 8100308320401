import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { sidebarItems } from "../../../utils";
import { Link } from "react-router-dom";
import { BuyAirtime, HomeIcon } from "../../Icons";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const ListAndHighlight = ({ itemsArr, customStyles, onclickAction }) => {
  const location = useLocation();
  const [elActive, setElActive] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    const activeIndex = sidebarItems.findIndex((item) => item.path === path);
    if (activeIndex !== -1) {
      setElActive(activeIndex);
    }
  }, [location.pathname]);

  const newActive =
    "bg-green-400 bg-opacity-30 border-r-4 border-r-green-600 border-green-400";

  return (
    <>
      {sidebarItems.map((item, index) => (
        <div
          className={`${index === elActive && "activew"} mx-3`}
          key={index}
          onClick={onclickAction && onclickAction}
        >
          <Link
            to={item.path}
            className={`${
              customStyles
                ? customStyles
                : "flex gap-4 items-center w-full text-white"
            }
              pl-3
            `}
          >
            {item.icon}
            {item.name}
          </Link>
        </div>
      ))}
    </>
  );
};
