export const MenuIcon = () => {
  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 12H22.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.5 21H22.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.5 3H22.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
