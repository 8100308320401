import React from 'react'
import { useSelector } from 'react-redux';
import AdminSidebar from './Admin';
// import DriverSidebar from './Driver';
import UsersSidebar from './Users';

export const IndexSidebar = ({ isOpen }) => {
  
    const auth = useSelector((state) => state.auth);
    const usseraccount =  auth?.user?.account_type;
 
    let sidebar = null;

    if (usseraccount === 1) {
      sidebar = <UsersSidebar />
    }else if(usseraccount === 2){
      sidebar = <AdminSidebar />
    }else if(usseraccount === 0){
      sidebar = <AdminSidebar />
    }

    return sidebar;
}

// || auth?.user?.account_type === "Partner"