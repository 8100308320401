import React from "react";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import Header from "./Layouts/Header";
import { IndexSidebar } from "./Layouts/Sidebars/Index";
import { SettingsPage } from "./Layouts/dasboard-home/SettingsPage";


export default function Settings() {

    // const navigate = useNavigate()
    const [sidebarReduce,] = useState(false) 
// Import React FilePond


// Register the plugins
 

    return (
        <div>
                <div className="gridContainer">
                 <Header />
                <div className="" style={{ width: sidebarReduce ? "100px" : "200px" }}>
                    <IndexSidebar />
                </div>
                <div className="fixed top-0 hidden lg:block sub-menu z-50">
                 </div>
                <div
                    style={{
                        width: sidebarReduce ? "calc(100% - 45px)" : "",
                        zIndex: sidebarReduce ? "45" : ""
                    }}
                    className="lg:main-p pt-21 home-bg pb-10 relative">
                    <Routes>
                        <Route path="" element={<SettingsPage/>} />
                    </Routes>

                </div>
              
            </div>
        </div>

    )
}