import React, { forwardRef } from "react";

export const CustomInput = forwardRef(
  ({ label, Errorlabel, ...props }, ref) => {
    return (
      <div className=" text-base font-normal">
        <label className=" font-medium text-base">
          {Errorlabel ? Errorlabel : label}
        </label>
        <div className=" relative">
          <input
            ref={ref}
            {...props}
            className=" max-w-[300px] pl-[10px] border-[1.5px] border-solid border-[#D0D5DD] rounded-lg h-[50px] w-full"
            placeholder={label}
          />
        </div>
      </div>
    );
  }
);
