import React from "react";

export const NetworkProvider = ({ onclick, name, value, selectedItem, src }) => {
  return (
    <div
      className={`network-item border-1 ${value} ${
        selectedItem === value ? "selected" : ""
      }`}
      onClick={onclick}
    >
      <span className="mr-2">
        <img
          className="network-img"
          src={src}
          alt={`${value} Airtime`}
        />
      </span>
      <span className="network-name">{name}</span>
      <span></span>
      {selectedItem === value && (
        <input
          type="checkbox"
          className="rounded-checkbox"
          checked={true}
          readOnly
        />
      )}
    </div>
  );
};  
