// CustomModal.js
import React from 'react';
import Modal from 'react-modal';

// Custom styles for the modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #17a2b8', // Add border
    borderRadius: '25px', // Add border radius
  },
  // overlay: {
  //   background: 'rgba(0, 0, 0, 0.5)',
  // },
};
 

const CustomModal = ({ isOpen, closeModal, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal} 
      style={customStyles}
      contentLabel="Example Modal"
    >
      {/* <button className='btn' onClick={closeModal}>Close Modal</button> */}
      {children}
    </Modal>
  );
};

export default CustomModal;
