export const HomeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // className="text-black" // Add a stroke color here
    >
      <path
        d="M13 16.5H11C10.17 16.5 9.5 17.17 9.5 18V21.5H14.5V18C14.5 17.17 13.83 16.5 13 16.5Z"
        stroke="currentColor" // Add stroke color here
        strokeWidth="2" // Set the stroke width
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0698 2.81997L3.13978 8.36997C2.35978 8.98997 1.85978 10.3 2.02978 11.28L3.35978 19.24C3.59978 20.66 4.95978 21.81 6.39978 21.81H17.5998C19.0298 21.81 20.3998 20.65 20.6398 19.24L21.9698 11.28C22.1298 10.3 21.6298 8.98997 20.8598 8.36997L13.9298 2.82997C12.8598 1.96997 11.1298 1.96997 10.0698 2.81997Z"
        stroke="currentColor" // Add stroke color here
        strokeWidth="2" // Set the stroke width
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
