import axios from "axios";
import { encryptData } from "../utils/util";

const register = (payload) => {
    return axios.post(process.env.REACT_APP_BASE_URL + "/auth/signup", payload);
};

const login = async (payload,) => {
 
    const response = await axios.post(process.env.REACT_APP_BASE_URL + "/auth/signin",
            payload
            );
        //  console.log('responsess')   
        //  console.log(response.data.accessToken)   
        //  console.log(response)   

        const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
        // console.log(salt);
    if (response.data.accessToken) {
        const userData = response.data.data;
        
        // Remove the 'password' and 'pin' fields from the user data
        const { password, pin,otp_verify,sessionToken, ...userDataWithoutSensitiveInfo } = userData;
        
        const userDataString = JSON.stringify(userDataWithoutSensitiveInfo);
        const encryptedData = encryptData(JSON.stringify(response.data.data), salt);
        localStorage.setItem("ISONU_OGBON_AWE",  encryptedData);
        localStorage.setItem("user", userDataString);
        localStorage.setItem("business", JSON.stringify(response.data.business));
        localStorage.setItem("lpin", JSON.stringify(response.data.accessToken)); 
    }
    return response.data;
};

const adminLogin = async (payload) => {
    const response = await axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/admin/login",
            payload
            );
        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("lynchpin", JSON.stringify(response.data.accessToken));
        }
    return response.data;
};

const verifyAccount = async (payload) => {
    const response = await axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/email/confirm",
            payload
        );
    if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};

const resendVerification = async (payload) => {
    const response = await axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/email/verify/resend/" + payload);
    return response.data;
};

const forgetPassword = async (payload) => {
    const response = await axios
        .post(process.env.REACT_APP_BASE_URL + "/auth/password/email",payload);
    return response.data;
};

const resetPassword = async (payload) => {
    const response = await axios.post(process.env.REACT_APP_BASE_URL + "/auth/password/reset", payload);
    return response;
};

const logout = () => {
    localStorage.removeItem("user");
};

const AuthService = {
    register,
    login,
    logout,
    verifyAccount,
    resendVerification,
    forgetPassword,
    resetPassword,
    adminLogin
};

export default AuthService;