import {
  BRTCowry,
  BuyAirtime,
  CableTV,
  DataIcon,
  DownArrow,
  ElectricityIcon,
  HomeIcon,
  Tickets,
  TransactionIcon,
  UpArrow,
} from "../components/Icons";

export const SignupArr = [
  { label: "Username", name: "Username" },
  { label: "First Name", name: "first_name" },
  { label: "Last Name", name: "last_name" },
  { label: "Email", name: "email" },
 
];

export const NewUsersArr = [
  {
    image: "assets/img/user.png",
    name: "Victor Eben",
    location: "Lekki",
    date: "May 6",
  },
  {
    image: "assets/img/user2.png",
    name: "Samuel Owolabi",
    location: "Ikeja",
    date: "May 6",
  },
];

export const tableRows = [
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Withdrawal",
    reference: "12C4",
    status: "Success",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
  {
    name: "Victor O.",
    phoneNumber: "2243434322",
    recipient: "To Eben",
    amount: 15000,
    fee: 100,
    transactionType: "Transfer",
    reference: "12C4",
    status: "Failed",
    date: "May 2",
  },
];

export const DownloadOptionsArr = [
  { option: "Download CSV", styles: "csvExport" },
  { option: "Download PDF", styles: "pdfExport" },
  { option: "Download JSON", styles: "jsonExport" },
];

export const TransactionTypesArr = [
  {
    name: "Successful Transaction",
    nfvalue: 20000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    h1: true,
  },
  {
    name: "Failed Transaction",
    nfvalue: 2000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    h1: true,
  },
  {
    name: "Refunded Transaction",
    nfvalue: 2000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    h1: true,
  },
  {
    name: "Pending Transaction",
    nfvalue: 2000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    h1: true,
  },
];

export const DashboardOverviewSectionArr = [
  {
    headingText: "Total Balance",
    // nfvalue: business[0].business_wallets[0].ngnamoun,
    nfvalue: 1000000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    arrowstyles: "orange-theme",
    arrow: <UpArrow />,
  },
  {
    headingText: "Total Recieved",
    nfvalue: 50000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    arrowstyles: "green-theme",
    arrow: <UpArrow />,
  },
  {
    headingText: "Total Spent",
    nfvalue: 1000,
    nfdisplay: "text",
    nfseperator: true,
    nfprefix: "₦",
    arrowstyles: "red-theme",
    arrow: <DownArrow />,
  },
];

export const sidebarItems = [
  {
    name: "Home",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    name: "Transaction",
    path: "/transactions",
    icon: <TransactionIcon />,
  },
  {
    name: "Buy Airtime",
    path: "/buyairtime",
    icon: <BuyAirtime />,
  },
  {
    name: "Data",
    path: "/buydata",
    icon: <DataIcon />,
  },
  {
    name: "Electricity",
    path: "/electicity",
    icon: <ElectricityIcon />,
  },
  {
    name: "BRT Cowry",
    path: "/cowrywise",
    icon: <BRTCowry />,
  },
  {
    name: "Transfer",
    path: "/transfer",
    icon: <TransactionIcon />,
  },
  {
    name: "Cable Tv",
    path: "/cabletv",
    icon: <CableTV />,
  },
  {
    name: "Flight Ticket",
    path: "/flight/comingsoon",
    icon: <Tickets />,
  },
  {
    name: "Train Ticket",
    path: "/train/comingsoon",
    icon: <Tickets />,
  },
  // Add more sidebar items as needed
];

export const networksArr = [
  {
    name: "9MOBILE",
    value: "9mobile",
    imageUrl:
      "https://res.cloudinary.com/philo001/image/upload/payscribe/y5zvoxpi93mrzajshrbg.png",
  },
  {
    name: "Glo",
    value: "glo",
    imageUrl:
      "https://res.cloudinary.com/philo001/image/upload/payscribe/tjr4pbkll2rofvzewitb.png",
  },
  {
    name: "Airtel",
    value: "airtel",
    imageUrl:
      "https://res.cloudinary.com/philo001/image/upload/payscribe/xhorroc66kgwh4h91shz.png",
  },
  {
    name: "Mtn",
    value: "mtn",
    imageUrl:
      "https://res.cloudinary.com/philo001/image/upload/payscribe/nnnuefw7qgwbu5o6mzm7.png",
  },
];

export const predefinedAmountsArr = [
  {
    amount: "100",
    price: "₦100",
  },
  {
    amount: "200",
    price: "₦200",
  },
  {
    amount: "500",
    price: "₦500",
  },
  {
    amount: "1000",
    price: "₦1,000",
  },
  {
    amount: "5000",
    price: "₦5,000",
  },
];
