import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../../store/slices/auth";
import { useFormik } from "formik";
import { loginValidation } from "../../../services/validation";
import Spinner from "../../dashboard/Layouts/Spinner";
import Signup from './Signup';

export const Login = () => {
  let formRef = useRef();
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const stopLoading = () => setLoading(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/dashboard");
    }
  }, []);
  const [successful, setSuccessful] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  const myStyle = {
    backgroundImage:
      "url('https://images.unsplash.com/photo-1576814547952-f8531781d7ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80')",
    height: "105vh",
    marginTop: "-45px",
    // fontSize:'50px',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

  const handleSubmit = (values) => {
    console.log("****************** values", values);
    if (email === "" || password === "") {
      toast.error("All fields are required");
    } else if (password.length < 8) {
      toast.error("The password must be at least 8 characters.");
    } else {
      setLoading(true);
      console.log("****************** values", values);
      // dispatch(login(values, navigate, stopLoading));
      dispatch(login(values, navigate, stopLoading))
        .then((d) => {
          // console.log(d)
          setSuccessful(true);
          setDisableBtn(false);
          // setLoading(false);
        })
        .catch((e) => {
          setSuccessful(false);
          setDisableBtn(false);
          setLoading(false);
          console.log("Error message ", e);
        });
    }
  };
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidation,
    onSubmit: handleSubmit,
  });

  const { email, password } = formik.values;
  const displayMessage = (message) => {
    // console.log(message);
    // console.log(message.status);
    if (!message.status) {
      toast.error(message.message);
      // toast.error('message');
    } else {
      toast.success(message.message);
      setTimeout(() => {
        window.location.href =
          window.location.protocol + "//" + window.location.host + "/dashboard";
      }, 1000);
      // navigate("/dashboard");
    }
    setSuccessful(false);
  };

  return (
    <div className="min-h-screen flex">
      <div className=" min-h-screen bg-gradient-to-tr from-[#009897] to-[#fff] lto-[#EF6C25] lbg-green-500 max-w-[40vw] w-full hidden tablet:flex justify-center items-center">
        <img src="/assets/img/favicon.png" className=" max-w-[200px]" alt="" />
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className=" flex flex-col  tablet:max-w-[60vw] w-full min-h-screen tablet:mx-auto justify-center items-center bg-white gap-y-8 py-5 px-6"
      >
        <h1 className=" text-3xl font-medium">Sign in</h1>
        <div className=" flex justify-center items-center w-[70%]">
          <div className="w-full h-full flex flex-col justify-center items-center gap-x-8 gap-y-4 mb-6">
            <div className=" text-base font-normal w-full max-w-[400px]">
              <label className="fw-500">
                {formik.touched.email && formik.errors.email ? (
                  <span className="text-red-500">{formik.errors.email}</span>
                ) : (
                  "Email"
                )}
              </label>
              <input
                className="max-w-[400px] pl-[10px] border-[1.5px] border-solid border-[#D0D5DD] rounded-lg h-[50px] min-w-full"
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // className="firstName"
                required
              />
            </div>

            <div className=" text-base font-normal w-full max-w-[400px]">
              <label className="fw-500 mt-4">
                {formik.touched.password && formik.errors.password ? (
                  <span className="text-red-500">{formik.errors.password}</span>
                ) : (
                  "Password"
                )}
              </label>
              <input
                className="max-w-[400px] pl-[10px] border-[1.5px] border-solid border-[#D0D5DD] rounded-lg h-[50px] min-w-full"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // className=" firstName "
                required
              />
            </div>
            <br></br>
            <br></br>
            <div className=" max-w-[400px] text-base font-normal text-left w-full">
              <Link to="/forget" className="fs-400 fw-500 text-primary ">
                Forget password ?
              </Link>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <input
                type="submit"
                // className="btn-primary"
                className=" activew max-w-[300px] py-3 text-base w-full rounded-lg mt-4"
                style={{ margintop: "0.25rem" }}
                value={"Login"}
                disabled={disableBtn}
              />
            )}
          </div>
        </div>
        <p className="text-base text-center mt-2">
          Don't have an account?{" "}
          <span
            onClick={() => {
              setTimeout(() => {
                window.location.href =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  "/signup";
              }, 300);
            }}
            className=" text-cyan-400 cursor-pointer"
          >
            Signup
          </span>
        </p>
      </form>
      {successful ? displayMessage(message) : ""}
    </div>
  );
};
