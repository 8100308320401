import React from 'react';

const MessageCounter = ({ responseData }) => {
  if (!responseData ) {
    return <div>0</div>;
  }
  let unreadCount = 0;
  responseData.forEach(item => {
    if (item.read === "0") {
      unreadCount++;
    }
  });
  return <div>{unreadCount}</div>;
};
export default MessageCounter;
