import React, { useState, useEffect } from 'react';
import CustomModal from './Modal/makepaymentModal';
import { useSelector,useDispatch } from "react-redux";
import { values } from '../../node_modules/@mui/system/esm/breakpoints';

const AutoLogout = () => {
    const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countdown, setCountdown] = useState(10); // Initial countdown value

    const user = useSelector((state) => state.auth.user);
    const fullname = user?.first_name + ' ' + user?.last_name;
    const openModal = () => {
        setShowModal(true);
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setShowModal(false);
        setIsModalOpen(false);
    };

    // Function to clear localStorage and redirect to logout
    const logoutAndClearStorage = () => {
        // Clear localStorage
        localStorage.clear();
        // Redirect to logout or login page
        // Replace '/logout' with your logout route
        window.location.replace('/');
    };
    const startCountdown = (countdownInterval) => {
        countdownInterval = setInterval(() => {
            setCountdown(countdown => {
                // console.log(countdown);
                if(countdown == 1){
                    handleLogout();
                }
                return countdown - 1;
            });
        }, 1000);
    };
    useEffect(() => {
        let logoutTimer;
        let countdownInterval;

        const resetLogoutTimer = () => {
            clearTimeout(logoutTimer);
            setCountdown(40);
            clearInterval(countdownInterval);
            logoutTimer = setTimeout(() => {
                setShowModal(true);
                setIsModalOpen(true); 
                startCountdown(countdownInterval)
            }, 20000); // 2 minute idle
        };
       

        const handleActivity = () => {
            resetLogoutTimer();
        };

        // Reset the timer on user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        // Initialize the timer
        resetLogoutTimer();

        // Cleanup
        return () => {
            clearInterval(countdownInterval); // Cleanup interval
            clearTimeout(logoutTimer);
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
        };
    }, []);

    const handleLogout = () => {
        logoutAndClearStorage();
    };

    return (
        <>
            {showModal && (
                <CustomModal isOpen={isModalOpen} onClose={closeModal} className="materialCard">
                    <div className="row">
                                <h1 className="titleWithDateDiv" > Security Check </h1>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; 
                                <h1 className="titleWithDateDiv text-end ml-5 text-deep-orange-800" onClick={closeModal}>Cancel</h1>
                    </div> 
                            <h2>Hi! <span className='text-deep-orange-800'>{fullname}</span> Are you still there?</h2>
                            {/* <p>You will be logged out due to inactivity.</p> */}
                            <p>You will be logged out due to inactivity in {countdown} seconds.</p>

                            <button type='button' className='btn-primary btn' onClick={handleLogout}>Logout</button>
                </CustomModal>
            )}
        </>
    );
};

export default AutoLogout;
